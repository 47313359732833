import React, { useEffect, useState } from "react";
import {
  PDFUIInstanceContext,
  PDFUIRenderToElementContext,
} from "./FoxitWebPDFContexts";
import * as UIExtension from "@foxitsoftware/foxit-pdf-sdk-for-web-library-full/lib/UIExtension.full.js";
import "@foxitsoftware/foxit-pdf-sdk-for-web-library-full/lib/UIExtension.css";
import * as Addons from "@foxitsoftware/foxit-pdf-sdk-for-web-library-full/lib/uix-addons/allInOne.js";
import * as mobileAddons from "@foxitsoftware/foxit-pdf-sdk-for-web-library-full/lib/uix-addons/allInOne.mobile.js";
import { licenseKey, licenseSN } from "../../license-key";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";
import { selectUser } from "../../redux/slices/userSlice";
import Popups from "../Model/base";
import { toast } from "react-toastify";

if (module.hot) {
  module.hot.dispose(() => {
    window.location.reload();
  });
}

function FoxitWebPDFAppComponent(props) {
  const [containerRef, setContainerRef] = useState(React.createRef());
  const fileURL = Cookies.get("file_url");
  const [popup, setPopup] = useState(false);
  const [PDFUI, setPDFUI] = useState(null);
  const [state, setState] = useState(-1);
  const [type, setType] = useState("new");
  const [initial, setInitial] = useState(true);
  const { profile } = useSelector(selectUser);
  const [login, setLogin] = useState(false);

  useEffect(() => {
    const session_id = Cookies.get("session_id");
    if (session_id) {
      setLogin(true);
    }
  }, [profile]);

  const checkPDFLink = (url, callback) => {
    fetch(url)
      .then((response) => {
        if (response.ok) {
          callback(false);
        } else {
          callback(true);
        }
      })
      .catch((error) => {
        callback(true);
      });
  };
  
  useEffect(() => {
    if (fileURL) {
      if (PDFUI) {
        checkPDFLink(fileURL, function (isBroken) {
          if (isBroken) {
            toast.error("The PDF link is broken.");
            Cookies.remove("file_url", {
              domain: process.env.REACT_APP_PUBLIC_DOMAIN,
              path: "/",
            });
            Cookies.remove("file_name", {
              domain: process.env.REACT_APP_PUBLIC_DOMAIN,
              path: "/",
            });
            Cookies.remove("file_Id", {
              domain: process.env.REACT_APP_PUBLIC_DOMAIN,
              path: "/",
            });
            setState(3);
            setPopup(true);
          } else {
            PDFUI.openPDFByHttpRangeRequest({
              range: {
                url: fileURL,
              },
            });
          }
        });
      }
    } else {
      if (!props.file) {
        setState(3);
        setPopup(true);
      }
    }
  }, [PDFUI]);

  useEffect(() => {
    const libPath = "/foxit-lib/";
    const renderToElement = document.createElement("div");
    renderToElement.id = "pdf-ui";

    UIExtension.PDFUI.module("custom", []).controller("handleFile", {
      handle: function () {
        setType("new");
        setState(3);
        setPopup(true);
      },
    });

    UIExtension.PDFUI.module("mergeFile", []).controller("handleMergeFile", {
      handle: function () {
        const session_id = Cookies.get("session_id");
        if (session_id) {
          setType("merge");
          setState(3);
          setPopup(true);
        } else {
          setState(2);
          setPopup(true);
        }
      },
    });

    UIExtension.PDFUI.module("pdfConverter", []).controller(
      "handlePDFConversion",
      {
        handle: async function () {
          const session_id = Cookies.get("session_id");
          if (session_id) {
            setState(4);
            setPopup(true);
          } else {
            setState(2);
            setPopup(true);
          }
        },
      }
    );

    UIExtension.PDFUI.module("pdfDownload", []).controller(
      "handlePDFDownload",
      {
        handle: async function () {
          const session_id = Cookies.get("session_id");
          if (session_id) {
            setState(9);
            setPopup(true);
          } else {
            setState(2);
            setPopup(true);
          }
        },
      }
    );

    const pdfui = new UIExtension.PDFUI({
      viewerOptions: {
        libPath,
        jr: {
          workerPath: libPath,
          enginePath: libPath + "/jr-engine/gsdk",
          fontPath: "/external/brotli",
          licenseSN,
          licenseKey,
        },
      },
      renderTo: renderToElement,
      appearance: UIExtension.appearances.Appearance.extend({
        getLayoutTemplate: function () {
          return [
            "<webpdf>",
            `    <div>`,
            `    <toolbar class="pdfMenu" style="display: flex; justify-content: center; flex-wrap:wrap;">`,
            // `        <open-file-dropdown></open-file-dropdown>`,
            `
                     <contextmenu name="fv--page-contextmenu" >
                      <contextmenu-item @controller="zoom:ZoomInAndOutController" action="zoomin" icon-class="fx-icon-ribbon_home_zoomin-32">toolbar.buttons.zoomin</contextmenu-item>
                      <contextmenu-item @controller="zoom:ZoomInAndOutController" action="zoomout" icon-class="fx-icon-ribbon_home_zoomout-32">toolbar.buttons.zoomout</contextmenu-item>
                     </contextmenu>
            `,
            `        <xbutton class="open-file" text="Open File" @controller="custom:handleFile"></xbutton>`,
            // `        <xbutton class="open-file new-file" text="New File" @controller="newPDFFile:handleNewFile"></xbutton>`,
            `        <hand-button></hand-button>`,
            // "        <edit-pageobjects:edit-all-objects-button></edit-pageobjects:edit-all-objects-button>",
            "        <page-editor:edit-text></page-editor:edit-text>",
            "        <page-editor:add-text-button> </page-editor:add-text-button>",
            "        <page-editor:edit-object-ribbon-dropdown></page-editor:edit-object-ribbon-dropdown>",
            "        <page-editor:join-split visible='false'></page-editor:join-split>",
            "        <find-replace:find-replace-button></find-replace:find-replace-button>",
            "        <page-editor:add-image-button></page-editor:add-image-button>",
            "        <page-editor:add-shapes-ribbon-dropdown visible='false'></page-editor:add-shapes-ribbon-dropdown>",

            '       <gtab name="edit-tab" group="toolbar-tab" body="fv--edit-tab-paddle" text="toolbar.tabs.edit.title" style="display:none!important"></gtab>',
            '       <gtab group="toolbar-tab" body="fv--joinsplit-tab-paddle" name="joinsplit-tab" text="toolbar.tabs.join&split.title" style="display:none!important"></gtab>',
            '       <paddle name="fv--protect-tab-paddle" visible="false"></paddle>',
            '       <div class="fv__ui-portfolio-container"  visible="false"></div>',
            "        <create-link-button></create-link-button>",
            // `        <dropdown class="image-dropdown"><create-image-button></create-image-button></dropdown>`,
            "        <ink-sign-dropdown></ink-sign-dropdown>",
            "        <create-ink-sign-dialog></create-ink-sign-dialog>",
            `        <download-file-button @controller="pdfDownload:handlePDFDownload"></download-file-button>`,
            `        <dropdown text="more" class="more-dropdown">
                        <dropdown class="fx-ribbon-item fv__ui-directive-tooltip fv__ui-directive-controller" name="freetext-textbox" tooltip-title="toolbar.tooltip.textbox.title" icon-class="fv__icon-toolbar-textbox" fv__nest-name_576218="freetext-textbox" component-name="freetext-textbox" component-type="ribbon-button" id="component-lfm5rwrf" data-webpdf_component="component-lfm5rwrf" role="button" aria-label="Textbox">
                          <form-designer:create-push-button-ribbon></form-designer:create-push-button-ribbon>
                          <form-designer:create-check-box-ribbon></form-designer:create-check-box-ribbon>
                          <form-designer:create-radio-button-ribbon></form-designer:create-radio-button-ribbon>
                          <form-designer:create-combo-box-ribbon></form-designer:create-combo-box-ribbon>
                          <form-designer:create-list-box-ribbon></form-designer:create-list-box-ribbon>
                          <form-designer:create-date-ribbon></form-designer:create-date-ribbon>
                        </dropdown>
                        <dropdown @controller="drawings:DrawingsDropdownController" name="create-shape-dropdown" class="fv__ui-dropdown-hide-text">
                          <create-square-button></create-square-button>
                          <create-circle-button></create-circle-button>
                          <create-line-button></create-line-button>
                          <create-arrow-button></create-arrow-button>
                          <create-polygon-button></create-polygon-button>
                          <create-polyline-button></create-polyline-button>
                          <create-cloud-button></create-cloud-button>
                        </dropdown>
                        <xbutton text="Merge File" @controller="mergeFile:handleMergeFile"></xbutton>
                        <xbutton text="Convert File" @controller="pdfConverter:handlePDFConversion"></xbutton>
                        <zoom-dropdown></zoom-dropdown>
                        <dropdown class="fx-ribbon-item fv__ui-directive-tooltip" tooltip-title="password-protect:toolbar.protect.secureDocument" text="password-protect:toolbar.protect.secureDocument" icon-class="fx-icon-ribbon_protect_security_document-32" not-immediately="true" name="password-protect-controllers" fv__nest-name_576218="password-protect-controllers" component-name="password-protect-controllers" component-type="ribbon-button" id="component-lfm5rx7a" data-webpdf_component="component-lfm5rx7a" role="button" aria-label="Secure Document" tabindex="0" aria-expanded="false">
                          <password-protect:password-protect-button></password-protect:password-protect-button>
                          <password-protect:remove-protect-button></password-protect:remove-protect-button>
                        </dropdown>
                     </dropdown>`,
            "    </toolbar>",
            `    </div>`,
            `<div class="fv__ui-body">
              <viewer></viewer>
              <sidebar-right min-width="300" max-width="360" name="sidebar-right" @lazy-content="shown" visible='true'>
                <sidebar-tabs name="sidebar-right-tabs">
                  <sidebar-tab-panel name="edit-properties-panel" tab-title="editProperties.tab.format">
                    <edit-properties:edit-properties name="edit-properties"></edit-properties:edit-properties>  
                  </sidebar-tab-panel>
                </sidebar-tabs>
              </sidebar-right>       
            </div>`,
            "</webpdf>",
          ].join("");
        },
      }),
      addons: UIExtension.PDFViewCtrl.DeviceInfo.isMobile
        ? mobileAddons
        : Addons,
    });

    setPDFUI(pdfui);

    return () => {
      if (PDFUI) {
        PDFUI.destroy();
      }
    };
  }, []);

  useEffect(() => {
    const pdfui = PDFUI;
    const container = containerRef.current;
    if (pdfui && container) {
      pdfui.renderTo.remove();
      container.appendChild(pdfui.renderTo);
    }
  }, [PDFUI, containerRef]);

  return (
    <PDFUIRenderToElementContext.Provider value={containerRef}>
      {PDFUI ? (
        <PDFUIInstanceContext.Provider value={PDFUI}>
          {props.children}
        </PDFUIInstanceContext.Provider>
      ) : (
        props.children
      )}
      {state && (
        <Popups
          show={popup}
          hide={setPopup}
          state={state}
          setstate={setState}
          initial={initial}
          setInitial={setInitial}
          PDFUI={PDFUI}
          type={type}
          className=""
        />
      )}
    </PDFUIRenderToElementContext.Provider>
  );
}
export const FoxitWebPDFApp = React.memo(FoxitWebPDFAppComponent);
