import { createSlice } from "@reduxjs/toolkit";
import { authService } from "../../services/auth.service";
export const userSlice = createSlice({
  name: "user",
  initialState: {
    isLoading: true,
    success: false,
    signUp: {},
    error: {},
    login: {},
    forgetPass: {},
    changePass: {},
    resendOTP: {},
    profile: {},
    reset_pin: ""
  },
  reducers: {
    signUpUpdate: (state, action) => {
      if (action.payload.success) {
        state.success = action.payload.success;
        state.isLoading = false;
        const keys = Object.keys(action.payload);
        keys.map((key) => (state.signUp[key] = action.payload[key]));
      } else {
        state.success = action.payload.success;
        state.isLoading = false;
        const keys = Object.keys(action.payload);
        keys.map((key) => (state.error[key] = action.payload[key]));
      }
    },

    loginUpdate: (state, action) => {
      if (action.payload.success) {
        state.success = action.payload.success;
        state.isLoading = false;
        const keys = Object.keys(action.payload);
        keys.map((key) => (state.login[key] = action.payload[key]));
      } else {
        state.success = action.payload.success;
        state.isLoading = false;
        const keys = Object.keys(action.payload);
        keys.map((key) => (state.error[key] = action.payload[key]));
      }
    },

    forgetPasswordUpdate: (state, action) => {
      if (action.payload.success) {
        state.success = action.payload.success;
        state.isLoading = false;
        const keys = Object.keys(action.payload);
        keys.map((key) => (state.forgetPass[key] = action.payload[key]));
      } else {
        state.success = action.payload.success;
        state.isLoading = false;
        const keys = Object.keys(action.payload);
        keys.map((key) => (state.error[key] = action.payload[key]));
      }
    },

    changePasswordUpdate: (state, action) => {
      if (action.payload.success) {
        state.success = action.payload.success;
        state.isLoading = false;
        const keys = Object.keys(action.payload);
        keys.map((key) => (state.changePass[key] = action.payload[key]));
      } else {
        state.success = action.payload.success;
        state.isLoading = false;
        const keys = Object.keys(action.payload);
        keys.map((key) => (state.error[key] = action.payload[key]));
      }
    },

    profileUpdate: (state, action) => {
      if (action?.payload?.success) {
        state.success = action.payload.success;
        state.isLoading = false;
        const keys = Object.keys(action.payload.data);
        keys.map((key) => (state.profile[key] = action.payload.data[key]));
      } else {
        state.success = action.payload.success;
        state.isLoading = false;
        const keys = Object.keys(action.payload);
        keys.map((key) => (state.error[key] = action.payload[key]));
      }
    },

    setTab: (state, action) => {
      state.tab = action.payload;
    },

    resendOTPUpdate: (state, action) => {
      if (action.payload.success) {
        state.success = action.payload.success;
        state.isLoading = false;
        const keys = Object.keys(action.payload);
        keys.map((key) => (state.resendOTP[key] = action.payload[key]));
      } else {
        state.success = action.payload.success;
        state.isLoading = false;
        const keys = Object.keys(action.payload);
        keys.map((key) => (state.error[key] = action.payload[key]));
      }
    },

    setResetPin: (state, action) => {
      state.reset_pin = action.payload
    },

    logout: (state) => {
      state.profile = {};
      authService.logout();
    },

    loadingFlag: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

export const {
  loginUpdate,
  logout,
  signUpUpdate,
  forgetPasswordUpdate,
  changePasswordUpdate,
  profileUpdate,
  resendOTPUpdate,
  setResetPin,
  loadingFlag,
  setTab,
} = userSlice.actions;

export const selectUser = (state) => state.user;

export default userSlice.reducer;
