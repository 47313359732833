/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { logout } from "../../redux/slices/userSlice";
import { useTranslation } from "react-i18next";


const ProfileDropdown = ({ profile }) => {
  const { t, i18n } = useTranslation()

  const dispatch = useDispatch();
  const html = document.getElementsByTagName('html')[0].dir

  return (
    <>
      <div className="text-right">
        <Menu as="div" className="relative inline-block text-left">
          <div>
            {profile.profileImage === "" ? (
              <Menu.Button className="md:w-10 lg:w-9 lg:h-9 xl:w-10 xl:h-10 w-8 md:h-10 h-8 rounded-full text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                <svg className="w-full h-full p-px scale-125"
                  xmlns="http://www.w3.org/2000/svg"
                  width="50"
                  height="50"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M12.1207 12.78C12.0507 12.77 11.9607 12.77 11.8807 12.78C10.1207 12.72 8.7207 11.28 8.7207 9.51001C8.7207 7.70001 10.1807 6.23001 12.0007 6.23001C13.8107 6.23001 15.2807 7.70001 15.2807 9.51001C15.2707 11.28 13.8807 12.72 12.1207 12.78Z"
                    stroke="#777777"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M18.7398 19.38C16.9598 21.01 14.5998 22 11.9998 22C9.39977 22 7.03977 21.01 5.25977 19.38C5.35977 18.44 5.95977 17.52 7.02977 16.8C9.76977 14.98 14.2498 14.98 16.9698 16.8C18.0398 17.52 18.6398 18.44 18.7398 19.38Z"
                    stroke="#777777"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                    stroke="#777777"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </Menu.Button>
            ) : (
              <Menu.Button>
                {/* <Image
                  width={50}
                  height={50}
                  alt="profile picture"
                  src={profile.profileImage}
                  className="inline-flex w-full justify-center rounded-full bg-black bg-opacity-20 p-2 text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
                /> */}
                <figure className="md:w-10 lg:w-9 lg:h-9 xl:w-10 xl:h-10 w-8 md:h-10 h-8 relative rounded-full">
                  <img
                    // unoptimized={true}
                    // fill={true}
                    alt="profile picture"
                    src={profile.profileImage}
                    className="inline-flex object-cover justify-center w-full h-full rounded-full bg-black bg-opacity-20 text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
                  />
                </figure>
              </Menu.Button>
            )}
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className={`${html === 'rtl' ? 'left-0' : 'right-0'} absolute mt-2 w-56 origin-top-right rounded-3xl bg-white shadow-xl ring-black ring-opacity-5 focus:outline-none flex flex-col gap-y-5 z-10 p-5`}>
              <Menu.Item>
                <div
                  onClick={() => {
                    window.location.href = `${process.env.REACT_APP_FRONTEND_URL}/${i18n.language}/profile`;
                  }}
                  className="cursor-pointer flex gap-x-2"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M12.1207 12.78C12.0507 12.77 11.9607 12.77 11.8807 12.78C10.1207 12.72 8.7207 11.28 8.7207 9.51001C8.7207 7.70001 10.1807 6.23001 12.0007 6.23001C13.8107 6.23001 15.2807 7.70001 15.2807 9.51001C15.2707 11.28 13.8807 12.72 12.1207 12.78Z"
                      stroke="#777777"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M18.7398 19.38C16.9598 21.01 14.5998 22 11.9998 22C9.39977 22 7.03977 21.01 5.25977 19.38C5.35977 18.44 5.95977 17.52 7.02977 16.8C9.76977 14.98 14.2498 14.98 16.9698 16.8C18.0398 17.52 18.6398 18.44 18.7398 19.38Z"
                      stroke="#777777"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                      stroke="#777777"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <h5 className={`${i18n.language === 'ar' && '!text-right'} text-[#2E2E2E] font-medium whitespace-nowrap`}>{t("Profile")}</h5>
                </div>
              </Menu.Item>
              <Menu.Item>
                <div
                  onClick={() => {
                    window.location.href = `${process.env.REACT_APP_FRONTEND_URL}/${i18n.language}/my-pdf`;
                  }}
                  className="flex gap-x-2 items-center cursor-pointer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M22 10V15C22 20 20 22 15 22H9C4 22 2 20 2 15V9C2 4 4 2 9 2H14"
                      stroke="#777777"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M22 10H18C15 10 14 9 14 6V2L22 10Z"
                      stroke="#777777"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M7 13H13"
                      stroke="#777777"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M7 17H11"
                      stroke="#777777"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <h5 className={`${i18n.language === 'ar' && '!text-right'} text-[#2E2E2E] font-medium whitespace-nowrap`}>{t("My PDF")}</h5>
                </div>
              </Menu.Item>
              <Menu.Item>
                <div
                  onClick={() => {
                    window.location.href = `${process.env.REACT_APP_FRONTEND_URL}/${i18n.language}/my-project`;
                  }}
                  className="flex gap-x-2 items-center cursor-pointer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M22 10V15C22 20 20 22 15 22H9C4 22 2 20 2 15V9C2 4 4 2 9 2H14"
                      stroke="#777777"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M22 10H18C15 10 14 9 14 6V2L22 10Z"
                      stroke="#777777"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M7 13H13"
                      stroke="#777777"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M7 17H11"
                      stroke="#777777"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <h5 className={`${i18n.language === 'ar' && '!text-right'} text-[#2E2E2E] font-medium whitespace-nowrap`}>{t("My AI Project")}</h5>
                </div>
              </Menu.Item>
              <Menu.Item>
                <div id="menu-item"
                  onClick={() => {
                    window.location.href = `${process.env.REACT_APP_FRONTEND_URL}/${i18n.language}/coupon`;
                  }}
                  className="flex gap-x-2 items-center cursor-pointer"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#000000" fill="none">
                    <path d="M10.8738 21.5123L8.84345 20.3072C8.32816 20.0014 8.07051 19.8484 7.78457 19.8396C7.47557 19.83 7.21336 19.9767 6.65655 20.3072C6.03294 20.6774 4.79293 21.697 3.99083 21.2108C3.5 20.9132 3.5 20.1574 3.5 18.6457V8C3.5 5.17157 3.5 3.75736 4.37868 2.87868C5.25736 2 6.67157 2 9.5 2H14.5C17.3284 2 18.7426 2 19.6213 2.87868C20.5 3.75736 20.5 5.17157 20.5 8V18.6457C20.5 20.1574 20.5 20.9132 20.0092 21.2108C19.2071 21.697 17.9671 20.6774 17.3434 20.3072C16.8282 20.0014 16.5705 19.8484 16.2846 19.8396C15.9756 19.83 15.7134 19.9767 15.1566 20.3072L13.1262 21.5123C12.5786 21.8374 12.3047 21.9999 12 21.9999C11.6953 21.9999 11.4214 21.8374 10.8738 21.5123Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M15 8L9 14" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M15 14H14.991M9.00897 8H9" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>


                  <h5 className={`${i18n.language === 'ar' && '!text-right'} text-[#2E2E2E] font-medium whitespace-nowrap`}>{t("Add Coupons")}</h5>
                </div>
              </Menu.Item>
              <Menu.Item>
                <div id="menu-item"
                  onClick={() => {
                    window.location.href = `${process.env.REACT_APP_FRONTEND_URL}/${i18n.language}/my-billing`;
                  }}
                  className="flex gap-x-2 items-center cursor-pointer"
                >
                  <svg
                    fill="#777777"
                    width="24"
                    height="24"
                    viewBox="0 0 32 32"
                    style={{
                      fillRule: "evenodd",
                      clipRule: "evenodd",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 2,
                    }}
                    xmlSpace="preserve"
                    xmlns="http://www.w3.org/2000/svg"
                    // xmlns: serif="http://www.serif.com/"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                  >
                    <path d="M15.002,8l-0.998,0c-0.552,0 -1,0.448 -1,1c0,0.552 0.448,1 1,1l1.002,-0l0.002,1.002c0.001,0.552 0.45,0.999 1.002,0.998c0.552,-0.001 0.999,-0.45 0.998,-1.002l-0.002,-0.998l0.998,0c0.552,0 1,-0.448 1,-1c0,-0.552 -0.448,-1 -1,-1l-1.002,0l-0.002,-1.002c-0.001,-0.552 -0.45,-0.999 -1.002,-0.998c-0.552,0.001 -0.999,0.45 -0.998,1.002l0.002,0.998Z" />
                    <path d="M26,9l-0,-4c-0,-0.796 -0.316,-1.559 -0.879,-2.121c-0.562,-0.563 -1.325,-0.879 -2.121,-0.879c-3.463,0 -10.537,0 -14,-0c-0.796,-0 -1.559,0.316 -2.121,0.879c-0.563,0.562 -0.879,1.325 -0.879,2.121l0,22c0,0.796 0.316,1.559 0.879,2.121c0.562,0.563 1.325,0.879 2.121,0.879c2.6,-0 10.316,0 13.999,0c0.552,0 1,-0.448 1,-1c0,-0.552 -0.448,-1 -1,-1c-3.683,0 -11.399,-0 -13.999,-0c-0.265,0 -0.52,-0.105 -0.707,-0.293c-0.188,-0.187 -0.293,-0.442 -0.293,-0.707c-0,-0 -0,-22 -0,-22c0,-0.265 0.105,-0.52 0.293,-0.707c0.187,-0.188 0.442,-0.293 0.707,-0.293l14,0c0.265,-0 0.52,0.105 0.707,0.293c0.188,0.187 0.293,0.442 0.293,0.707c-0,-0 -0,4 -0,4c-0,0.552 0.448,1 1,1c0.552,0 1,-0.448 1,-1Z" />
                    <path d="M23.982,14.206c-1.159,0.414 -1.99,1.523 -1.99,2.825c0,1.659 1.333,2.987 2.992,2.987c0.553,0 1.008,0.448 1.008,1.001c0,0.552 -0.448,1 -1,1c-0.552,-0 -1,-0.448 -1,-1c0,-0.552 -0.448,-1 -1,-1c-0.552,-0 -1,0.448 -1,1c0,1.302 0.832,2.412 1.993,2.826l-0.005,1.163c-0.002,0.552 0.444,1.002 0.996,1.004c0.552,0.003 1.002,-0.444 1.004,-0.996l0.005,-1.166c1.168,-0.41 2.007,-1.523 2.007,-2.831c0,-1.655 -1.354,-3.001 -3.008,-3.001c-0.549,0 -0.992,-0.438 -0.992,-0.987c0,-0.552 0.448,-1 1,-1c0.55,0 0.997,0.445 1,0.994l0,0.013c0.004,0.548 0.45,0.993 1,0.993c0.552,0 1,-0.448 1,-1c0,-1.309 -0.84,-2.423 -2.01,-2.832l0.01,-1.179c0.004,-0.552 -0.44,-1.003 -0.992,-1.008c-0.552,-0.004 -1.004,0.441 -1.008,0.992l-0.01,1.202Z" />
                    <path d="M11,17.019l8,-0c0.552,-0 1,-0.448 1,-1c-0,-0.552 -0.448,-1 -1,-1l-8,-0c-0.552,-0 -1,0.448 -1,1c-0,0.552 0.448,1 1,1Z" />
                    <path d="M11,21.031l8,-0c0.552,-0 1,-0.448 1,-1c-0,-0.552 -0.448,-1 -1,-1l-8,-0c-0.552,-0 -1,0.448 -1,1c-0,0.552 0.448,1 1,1Z" />
                    <path d="M11,25.012l8,0c0.552,0 1,-0.448 1,-1c0,-0.552 -0.448,-1 -1,-1l-8,0c-0.552,0 -1,0.448 -1,1c0,0.552 0.448,1 1,1Z" />
                  </svg>
                  <h5 className={`${i18n.language === 'ar' && '!text-right'} text-[#2E2E2E] font-medium whitespace-nowrap`}>{t("My Billing")}</h5>
                </div>
              </Menu.Item>
              <Menu.Item>
                <div id="menu-item"
                  onClick={() => {
                    window.location.href = `${process.env.REACT_APP_FRONTEND_URL}/${i18n.language}/subscription-plans`;
                  }}
                  className="flex gap-x-2 items-center cursor-pointer"
                >
                  <svg className="flex-shrink-0"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M3.60059 19.3901C3.62587 19.4942 3.67245 19.5922 3.73754 19.6782C3.80261 19.7642 3.88484 19.8365 3.97929 19.8907C4.07373 19.9448 4.17842 19.9798 4.28708 19.9935C4.39573 20.0072 4.50608 19.9992 4.6115 19.9702C9.44525 18.6673 14.5502 18.6669 19.3843 19.9688C19.4896 19.9978 19.5999 20.0057 19.7085 19.9921C19.8172 19.9784 19.9219 19.9435 20.0162 19.8893C20.1106 19.8352 20.1928 19.7629 20.2579 19.6769C20.3231 19.591 20.3696 19.493 20.3949 19.389L22.9785 8.67172C23.013 8.52825 23.0058 8.3782 22.9579 8.23848C22.9098 8.09875 22.8227 7.97489 22.7065 7.88086C22.5902 7.78683 22.4494 7.72636 22.2998 7.70625C22.1502 7.68616 21.9977 7.70721 21.8598 7.76708L16.7327 9.99151C16.5475 10.0718 16.3383 10.0816 16.1462 10.0189C15.954 9.95614 15.7929 9.82548 15.6945 9.65259L12.7092 4.40714C12.639 4.28374 12.5362 4.18092 12.4116 4.10934C12.287 4.03775 12.145 4 12.0004 4C11.8558 4 11.7138 4.03775 11.5892 4.10934C11.4645 4.18092 11.3618 4.28374 11.2916 4.40714L8.30628 9.65259C8.20789 9.82548 8.04677 9.95614 7.85463 10.0189C7.6625 10.0816 7.45326 10.0718 7.26811 9.99151L2.1402 7.76675C2.00225 7.70689 1.84992 7.68582 1.70037 7.70592C1.55082 7.726 1.40997 7.78643 1.2937 7.88041C1.17743 7.97441 1.09034 8.09822 1.04225 8.2379C0.994157 8.37758 0.986964 8.52761 1.02148 8.67107L3.60059 19.3901Z"
                      stroke="#777777"
                      strokeWidth="1.5"
                    />
                  </svg>
                  <h5 className={`${i18n.language === 'ar' && '!text-right'} text-[#2E2E2E] font-medium whitespace-nowrap`}>{t("Upgrade Now")}</h5>
                </div>
              </Menu.Item>
              <hr />
              <Menu.Item>
                <div
                  className="cursor-pointer"
                  onClick={async () => {
                    Cookies.remove("session_id", {
                      domain: process.env.REACT_APP_PUBLIC_DOMAIN,
                      path: "/",
                    });
                    dispatch(logout());
                  }}
                >
                  <div className="flex gap-x-2 items-center cursor-pointer">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M15.0996 16.44C14.7896 20.04 12.9396 21.51 8.88961 21.51L8.75961 21.51C4.28961 21.51 2.49961 19.72 2.49961 15.25L2.49961 8.74001C2.49961 4.27001 4.28961 2.48001 8.75961 2.48001L8.88961 2.48001C12.9096 2.48001 14.7596 3.93001 15.0896 7.47001"
                        stroke="#777777"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M11.0009 12L20.3809 12"
                        stroke="#777777"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M18.15 15.35L21.5 12L18.15 8.64999"
                        stroke="#777777"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <h5 className="text-[#2E2E2E] font-Circular-Medium">
                      {t("Sign out")}
                    </h5>
                  </div>
                </div>
              </Menu.Item>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </>
  );
};
export default ProfileDropdown;
