import React, { useState } from "react";
import { GrFormClose } from "react-icons/gr";
import { AiOutlineCloudUpload } from "react-icons/ai";
import ProgressBarLine from "../Progressbar/progressbar";
import Button from "../Button/Button";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { PDFDocument } from 'pdf-lib';


function UploadFileModel({ setstate, PDFUI, type, initial, setInitial }) {
  const [file, setFile] = useState(null);
  const [fileType, setFileType] = useState("open");
  const [upload, setUpload] = useState(false);
  const [progress, setProgress] = useState(0);
  const [disableBtn, setDisableBtn] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const { t } = useTranslation()


  const formatBytes = (bytes, decimals = 2) => {
    if (!+bytes) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB ", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  };

  const isPDFValid = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = async (event) => {
        try {
          const arrayBuffer = event.target.result;
          await PDFDocument.load(arrayBuffer);
          resolve(true);
        } catch (error) {
          console.error('Error validating file:', error);
          resolve(false);
        }
      };
      reader.onerror = () => {
        console.error('Error reading file');
        resolve(false);
      };
      reader.readAsArrayBuffer(file);
    });
  };

  const handleUpload = async () => {
    if (file) {
      const isValidPDF = await isPDFValid(file);

      if (!isValidPDF) {
        toast.error(t("The selected PDF file is corrupted. Please select a valid PDF file."));
        return;
      }
      setUpload(true);
      setProgress(100);

      if (type === "new") {
        Cookies.set("file_name", file?.name, {
          domain: process.env.NEXT_PUBLIC_DOMAIN,
          path: "/",
        });

        Cookies.remove("file_Id", {
          domain: process.env.REACT_APP_PUBLIC_DOMAIN,
          path: "/",
        });

        Cookies.remove("file_url", {
          domain: process.env.REACT_APP_PUBLIC_DOMAIN,
          path: "/",
        });

        setInitial(false);

        PDFUI.openPDFByFile(file);
      } else {
        PDFUI.getPDFDocRender().then(async (document) => {
          const PDFDoc = document.getPDFDoc();
          // const pageCount = PDFDoc.getPageCount();
          // console.log(pageCount); // I did get the page count here
          // console.log(file); // and file object here
          const docFile = await PDFUI.loadPDFDocByFile(file);
          // console.log("docFile", docFile);
          const mergeFile = await PDFDoc.mergePDFDoc({
            doc: docFile,
          });
        });
      }

      setstate();
    } else {
      toast.error(t("Kindly select a file"));
    }
  };

  const handleNewFile = async () => {
    Cookies.remove("file_name");
    Cookies.remove("file_Id");

    await PDFUI.createNewDoc(
      "GoPDF.pdf",
      "GoPDF",
      { height: 842, width: 595 },
      { isRenderOnDocLoaded: true },
      PDFUI.pdfEngine
    );

    setstate();
  };
  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setIsDragging(false);
    const droppedFile = event.dataTransfer.files[0];
    if (droppedFile) {
      if (droppedFile.size > 200000000) {
        toast.error("File size cannot be more than 200 MB");
      } else {
        var reader = new FileReader();
        reader.readAsBinaryString(droppedFile);
        reader.onloadend = function () {
          if (reader.result) {
            const count = (reader.result.match(/\/Type[\s]*\/Page[^s]/g) || []).length;
            if (count <= 500) {
              setFile(droppedFile);
            } else {
              toast.error("Page limit is 500 Pages");
            }
          } else {
            toast.error("Failed to read the file. Please try again.");
          }
        };
      }
    }
  };


  return (
    <div className="max-w-xl w-full py-8 xl:px-8 sm:px-10 px-6 m-auto rounded-lg">
      <h1 className="text-xl md:text-2xl text-center text-black1 mb-6 mt-4">
        {t("Upload and attach files")}
      </h1>

      <div className="flex justify-center sm:flex-row flex-wrap md:flex-nowrap gap-2.5 sm:gap-4 mb-3">
        <Button
          className="py-4"
          onClick={() => {
            setFileType("open");
          }}
        >
          {t("Open Existing PDF")}
        </Button>
        <Button
          className="py-4 !bg-white !text-themecolor !border !border-themecolor"
          onClick={() => {
            setFileType("new");
          }}
        >
          {t("Create new PDF")}
        </Button>
        {/* <span
          onClick={() => {
            setFileType("open");
          }}
          className={`py-2 px-3 cursor-pointer whitespace-nowrap sm:w-auto w-full text-center ${fileType === "open"
            ? "bg-themecolor text-[#ffffff] rounded-[15px] font-bold"
            : "text-themecolor rounded-[15px] font-bold border border-themecolor"
            }`}
        >
          {t("Open Existing PDF")}
        </span>
        <span
          onClick={() => {
            setFileType("new");
          }}
          className={`py-[7px] sm:py-2 px-3 cursor-pointer whitespace-nowrap sm:w-auto w-full text-center ${fileType === "new"
            ? "bg-themecolor text-[#ffffff] rounded-[15px] font-bold"
            : "text-themecolor rounded-[15px] font-bold border border-themecolor"
            }`}
        >
          {t("Create new PDF")}
        </span> */}
      </div>

      {fileType === "open" ? (
        <label className="relative cursor-pointer block py-10 mb-4 border-dashed w-full border border-black1 px-4 md:px-6 rounded-md "
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          <input
            accept=".pdf"
            type="file"
            className="w-0 hidden absolute"
            value={[]}
            onChange={(e) => {
              const selectedFile = e.target.files[0];
              if (selectedFile["size"] > 200000000) {
                toast.error("File size cannot be more than 200 MB");
              } else {
                var reader = new FileReader();
                reader.readAsBinaryString(selectedFile);
                reader.onloadend = function () {
                  if (reader.result) {
                    // @ts-ignore
                    const count = (reader.result.match(/\/Type[\s]*\/Page[^s]/g) || []).length;

                    if (count <= 500) {
                      setFile(selectedFile);
                    } else {
                      toast.error("Page limit is 500 Pages");
                    }
                  } else {
                    toast.error("Failed to read the file. Please try again.");
                  }
                };
              }
            }}
          />

          <div className="flex flex-col justify-center gap-1 items-center w-full text-center">
            {/* <i className="icon-uplaodfile h-[4.375rem] w-[4.375rem] mb-4 rounded-full bg-black flex justify-center items-center text-[2.375rem] text-white"></i> */}
            <div className="h-[4.375rem] w-[4.375rem] mb-4 rounded-full bg-black flex justify-center items-center text-[2.375rem] text-white">
              <AiOutlineCloudUpload />
            </div>
            <p className="text-black text-center text-base">
              {" "}
              <span className="border-b border-black">{t("Click to upload")}</span> {t("or drag and drop")}
            </p>
            <p className="text-black text-center text-base">
              {" "}
              <span className="border-b border-black">
                {t("File should be less than 200 MB")}
              </span>{" "}
              {t("and allowed page size is 500")}
            </p>
            <p className="text-lightgray text-center text-base">{t("Only one file upload")}</p>
          </div>
        </label>
      ) : (
        <label className="relative cursor-pointer block py-10 mb-4 border-dashed w-full h-[13.5rem] border border-black1  rounded-md ">
          <div
            onClick={() => {
              handleNewFile();
            }}
            className="flex flex-col justify-center cursor-pointer  items-center w-full"
          >
            {/* <i className="icon-uplaodfile h-[4.375rem] w-[4.375rem] mb-4 rounded-full bg-black flex justify-center items-center text-[2.375rem] text-white"></i> */}
            <div className="h-[4.375rem] w-[4.375rem] mb-4 rounded-full bg-black flex justify-center items-center text-[2.375rem] text-white">
              <AiOutlineCloudUpload />
            </div>
            <p className="text-black text-base">
              {" "}
              <span className="border-b border-black">{t("New Blank File")}</span>
            </p>
          </div>
        </label>
      )}
      {file && (
        <div className="border border-black1 relative pt-4 pb-5 rounded-md mb-10">
          {!upload && (
            <GrFormClose
              onClick={() => {
                setFile(null);
              }}
              className="absolute top-2 right-2 text-3xl text-[#2E2E2E]"
            />
          )}
          <div className="flex items-start gap-4 px-4 mb-2">
            <i className="py-2 px-2  border border-[#D9D9D9] rounded-[10px] icon-pdffile text-black1 text-[2.75rem]"></i>
            <div className="w-full">
              <p
                className="text-black1 text-base"
                style={{ overflowWrap: "anywhere", padding: "15px 3px 0" }}
              >
                {
                  //@ts-ignore
                  file.name
                }
              </p>
              <p className="text-black1 text-base">
                {
                  //@ts-ignore
                  formatBytes(file.size)
                }
              </p>

              <div className="mt-5">
                {!upload ? (
                  <div />
                ) : (
                  <div>
                    <ProgressBarLine percent={progress} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="grid md:grid-cols-2 grid-cols-1 gap-4 w-full">
        <Button
          className="py-4 px-[5.875rem] rounded-[10px] !border !border-[#D9D9D9] text-black bg-transparent"
          onClick={() => {
            if (initial) {
              toast.warn(t("Kindly upload a file or create new file"));
            } else {
              setstate();
            }
          }}
        >
          {t("Cancel")}
        </Button>

        {fileType === "open" ? (
          <Button
            disabled={disableBtn}
            className="py-4 px-[5.875rem] rounded-[10px]"
            onClick={() => {
              handleUpload();
            }}
          >
            {t("Upload File")}
          </Button>
        ) : (
          <Button
            disabled={disableBtn}
            className="py-4 px-[5.875rem] rounded-[10px]"
            onClick={() => {
              handleNewFile();
            }}
          >
            {t("Create new File")}
          </Button>
        )}
      </div>
    </div>
  );
}

export default UploadFileModel;
