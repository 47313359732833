import * as React from "react";
const SaveIcon = ({ width, height, className }) => (
    <span className={`${className}`}>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={30}
            height={30}
            viewBox="0 0 30 30"
            fill="none"
        >
            <path
                d="M25.8145 9.98929L25.8143 9.98953L25.8243 9.99734C27.0574 10.9564 27.75 12.4692 27.75 14.1333V22.2222C27.75 25.2619 25.2619 27.75 22.2222 27.75H20.7778H9.22222H7.77778C4.73807 27.75 2.25 25.2619 2.25 22.2222V7.77778C2.25 4.73807 4.73807 2.25 7.77778 2.25H14.8556C16.2312 2.25 17.4751 2.80005 18.4481 3.63402C18.4482 3.6341 18.4483 3.63418 18.4484 3.63426L25.8145 9.98929ZM19.3333 25.3611H19.5833V25.1111V20.7778C19.5833 20.2876 19.4189 19.8598 19.1129 19.5538C18.8069 19.2478 18.379 19.0833 17.8889 19.0833H12.1111C11.621 19.0833 11.1931 19.2478 10.8871 19.5538C10.5811 19.8598 10.4167 20.2876 10.4167 20.7778V25.1111V25.3611H10.6667H19.3333ZM21.9722 25.1111V25.3611H22.2222C23.9492 25.3611 25.3611 23.9492 25.3611 22.2222V14.1333C25.3611 13.177 24.8818 12.3949 24.2768 11.7899L24.2703 11.7834L24.2633 11.7774L16.8966 5.42182L16.8902 5.41624L16.8833 5.41111C16.2836 4.96133 15.6535 4.63889 14.8556 4.63889H7.77778C6.05082 4.63889 4.63889 6.05082 4.63889 7.77778V22.2222C4.63889 23.9492 6.05082 25.3611 7.77778 25.3611H8.02778V25.1111V20.7778C8.02778 18.4603 9.79363 16.6944 12.1111 16.6944H17.8889C20.2064 16.6944 21.9722 18.4603 21.9722 20.7778V25.1111Z"
                fill="white"
                stroke="#5E5ED2"
                strokeWidth={0.5}
            />
            <path
                d="M9.4 9.25H13.6C13.9578 9.25 14.2391 9.37619 14.4322 9.58308C14.6268 9.79153 14.75 10.1019 14.75 10.5C14.75 10.8981 14.6268 11.2085 14.4322 11.4169C14.2391 11.6238 13.9578 11.75 13.6 11.75H9.4C9.04222 11.75 8.76086 11.6238 8.56776 11.4169C8.37321 11.2085 8.25 10.8981 8.25 10.5C8.25 10.1019 8.37321 9.79153 8.56776 9.58308C8.76086 9.37619 9.04222 9.25 9.4 9.25Z"
                fill="white"
                stroke="#5E5ED2"
                strokeWidth={0.5}
            />
        </svg>
    </span>
);
export default SaveIcon;
