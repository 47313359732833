import { useTranslation } from "react-i18next";

const DescriptionBanner = ({ title, description }) => {
  const { t } = useTranslation()

  return (
    <section className={"bg-themecolor py-20 sm:py-28 md:py-40"}>
      <div className={"container flex flex-col justify-center items-center text-center"}>
        {/* <h2 className="md:text-5xl text-4xl text-white max-w-[52.625rem] sm:leading-15">
          "{title
            ? title
            : t('Simplify PDF Management with an Integrated Software Suite')}"
        </h2> */}
        <h2 aria-level={2} className="md:text-5xl text-4xl text-white max-w-[52.625rem] sm:leading-15">
          {title}
        </h2>
        {/* <p className="max-w-[50rem] mt-4 text-white font-Circular-Medium">
        {description
          ? description
          : "Effortlessly manage, edit, and convert PDF files with our comprehensive all-in-one software solution, designed to simplify your PDF experience."}
      </p> */}
        {description ? <div className="max-w-[50rem] my-4 mx-auto">{typeof (description) === 'string' ? <p className="max-w-[50rem] mt-4 text-white font-Circular-Medium">{description}</p> : description}</div> : ""}

        {/* <Button className="border rounded-full border-white w-max !py-5 !px-7 mt-6 capitalize">Get Started</Button> */}
      </div>
    </section>
  );
};
export default DescriptionBanner;
