import { HttpService } from "./base.service";

class AzureService extends HttpService {
  prefix = "azure";

  /**
   * User
   * @paramdata
   */

  projectStatus = (data) => this.post(`${this.prefix}/project_status`, data);
}

export const azureService = new AzureService();
