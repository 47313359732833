const SearchIcon = ({ ...props }) => (
  <span className={props.className}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={30}
      height={30}
      viewBox="0 0 30 30"
      fill="none"
      {...props}
    >
      <path
        d="M14.375 26.25C20.9334 26.25 26.25 20.9334 26.25 14.375C26.25 7.81662 20.9334 2.5 14.375 2.5C7.81662 2.5 2.5 7.81662 2.5 14.375C2.5 20.9334 7.81662 26.25 14.375 26.25Z"
        stroke="white"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.5 27.5L23.125 23.125"
        stroke="white"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.22884 12.7572C9.13015 13.152 9.08078 13.5633 9.08078 13.9745C9.08078 17.0011 11.5399 19.4603 14.5748 19.4685C15.8085 19.4685 16.9435 19.0408 17.8564 18.3581"
        stroke="white"
        strokeWidth={1.3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.2921 9.59124C12.2132 8.90038 13.34 8.4809 14.5737 8.4809C17.6003 8.4809 20.0595 10.94 20.0677 13.9749C20.0677 14.4026 20.0101 14.8056 19.9197 15.1922"
        stroke="white"
        strokeWidth={1.3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.87632 14.7975L9.07227 12.6016L11.2682 14.7975"
        stroke="white"
        strokeWidth={1.3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.2721 13.1525L20.0762 15.3484L17.8802 13.1525"
        stroke="white"
        strokeWidth={1.3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </span>
);
export default SearchIcon;
