const defaultMetadata = {
    title: 'Best Online PDF Editor Tool | Free Edit & Convert PDF - GoPDF',
    description: 'Free online PDF editor tool to edit, compress, crop, sign, merge, protect, convert PDF to JPG or PDF to word. Click, Edit & Convert with GoPDF!',
};
const pageMetadata = {
    editPdf: {
        site_name: "Free PDF Editor Tools Online - GoPDF",
        url: "https://editor.gopdf.io/edit-pdf",
        title: 'Edit PDF | Edit PDF Online Free - GoPDF',
        description: 'Edit PDF Online Free. Seamlessly edit documents, insert text, shapes, comments, and highlights into your PDF files. Fill out PDF forms and enhance your documents with annotations effortlessly.',
    },
    fillAndSignPdf: {
        site_name: "Free PDF Editor Tools Online - GoPDF",
        url: "https://editor.gopdf.io/fill-and-sign",
        title: 'Sign PDF Online Free | Digital signature of documents',
        description: 'Sign PDFs online for free and add your digital signature to important documents. GoPDF makes the process simple, secure, and convenient.',
    },
    searchAndReplace: {
        site_name: "Free PDF Editor Tools Online - GoPDF",
        url: "https://editor.gopdf.io/fill-and-sign",
        title: 'Sign PDF Online Free | Digital signature of documents',
        description: 'Sign PDFs online for free and add your digital signature to important documents. GoPDF makes the process simple, secure, and convenient.',
    },
};
export function getPageMetadata(pageName) {
    return pageMetadata[pageName] || defaultMetadata;
}
