import { useState } from "react";
import ConvertPopup from "./ConvertModel";
import Modal from "./layout";
import UploadFileModel from "./uploadFileModel";
import DownloadAlertModal from "./downloadAlert";
import LoginModal from "./loginmodal";
import SignupModal from "./signupmodal";
import ForgetPasswordModal from "./forgetpasswordmodal";
import EmailSentModal from "./emailsentmodal";
import ResetPasswordModal from "./resetpasswordmodal";
import CongratulationModal from "./congratulationmodal";
import OTPVerifyModal from "./otpverifymodel";
import UpgradePlanModal from "./upgradePlanModal";

const Popups = ({
  show,
  hide,
  state,
  setstate,
  PDFUI,
  type,
  initial,
  setInitial,
  className,
}) => {
  const [email, setEmail] = useState("");

  return (
    <div>
      <Modal show={show} hide={hide} className={`${className} my-16`}>
        {(() => {
          switch (state) {
            case 1: {
              return <LoginModal setstate1={setstate} setPopup={hide} />;
            }
            case 2: {
              return <SignupModal setstate1={setstate} setPopup={hide} />;
            }
            case 3: {
              return (
                <UploadFileModel
                  setstate={setstate}
                  initial={initial}
                  setInitial={setInitial}
                  PDFUI={PDFUI}
                  type={type}
                />
              );
            }
            case 4: {
              return <ConvertPopup setstate1={setstate} PDFUI={PDFUI} />;
            }
            case 5: {
              return (
                <ForgetPasswordModal setEmail={setEmail} setstate1={setstate} />
              );
            }
            case 6: {
              return <EmailSentModal setstate1={setstate} />;
            }
            case 7: {
              return <ResetPasswordModal email={email} setstate1={setstate} />;
            }
            case 8: {
              return <CongratulationModal setstate1={setstate} />;
            }
            case 9: {
              return (
                <DownloadAlertModal
                  setstate1={setstate}
                  PDFUI={PDFUI}
                  setPopup={hide}
                />
              );
            }
            case 10: {
              return <OTPVerifyModal setstate1={setstate} email={email} />;
            }
            case 11: {
              return (
                <UpgradePlanModal
                  setstate1={setstate}
                  setPopup={hide}
                  title="It's time to upgrade."
                  message="You've reached limit of your current plan, To add another set, you need to choose a higher plan."
                />
              );
            }
            default:
              break;
          }
        })()}
      </Modal>
    </div>
  );
};

export default Popups;
