const UploadIcon = ({ width, height, className }) => (
  <span className={`${className}`}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={"auto"}
      height={"auto"}
      viewBox="0 0 80 80"
      fill="none"
    >
      <circle cx={40} cy={40} r={40} fill="#5E5ED2" />
      <path
        d="M33.3734 45.9739L33.3739 45.9734L38.4615 41.1673C38.8412 40.8087 39.2455 40.5 39.9992 40.5C40.4034 40.5 40.674 40.5721 40.8854 40.6748C41.1037 40.7809 41.2895 40.9337 41.5163 41.1479L46.6244 45.9734L46.6249 45.9739C47.0277 46.3534 47.2134 46.7861 47.2134 47.2857C47.2134 48.3932 46.1925 49.2143 45.142 49.2143C44.6022 49.2143 44.1219 49.0345 43.711 48.6465L42.9139 47.8936L42.0706 47.0972V48.2571V57C42.0706 58.0379 41.1698 58.9286 39.9992 58.9286C38.8286 58.9286 37.9277 58.0379 37.9277 57V48.2571V47.0972L37.0844 47.8936L36.2873 48.6465C35.8764 49.0345 35.3961 49.2143 34.8563 49.2143C33.8056 49.2143 32.7849 48.3943 32.7849 47.2857C32.7849 46.7861 32.9706 46.3534 33.3734 45.9739ZM54.8685 31.6104L54.8949 31.8749L55.129 32.0009C58.0887 33.5948 60.0706 36.5848 60.0706 40C60.0706 45.0297 55.7718 49.1612 50.3888 49.2138C50.6391 48.6197 50.7849 47.9717 50.7849 47.2857C50.7849 46.6028 50.6457 45.9562 50.3903 45.3562C53.4281 45.3028 55.9277 42.9461 55.9277 40C55.9277 38.0681 54.8173 36.2874 53.0559 35.34L50.9682 34.2155L50.7438 31.9676L50.7438 31.9676C50.3405 27.9317 46.8033 24.9286 42.5706 24.9286C38.6969 24.9286 35.2976 27.5272 34.5217 31.155L33.7499 34.7611L29.6223 34.643L29.6105 34.6427L29.5987 34.6429C26.5655 34.6997 24.0706 37.0568 24.0706 40C24.0706 42.9463 26.5705 45.3031 29.6086 45.3562C29.3527 45.9563 29.2134 46.6025 29.2134 47.2857C29.2134 47.9717 29.3592 48.6197 29.6095 49.2138C24.2265 49.1612 19.9277 45.0297 19.9277 40C19.9277 34.938 24.282 30.7857 29.7134 30.7857C29.7392 30.7857 29.7682 30.7872 29.8098 30.7898C29.8151 30.7902 29.8206 30.7905 29.8264 30.7909C29.8622 30.7933 29.9088 30.7963 29.955 30.7976L30.3712 30.8095L30.4582 30.4024C31.5927 25.0988 36.5679 21.0714 42.5706 21.0714C49.0226 21.0714 54.2802 25.7211 54.8685 31.6104Z"
        fill="#F6F6FD"
        stroke="#5E5ED2"
      />
    </svg>
  </span>
);
export default UploadIcon;
