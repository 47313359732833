import { useTranslation } from "react-i18next";
import Button from "../Button/Button";
import { AiOutlineInfoCircle } from "react-icons/ai";

const UpgradePlanModal = ({ setstate1, setPopup, title, message }) => {
  const {t, i18n} = useTranslation();
  return (
    <div className="flex flex-col items-center p-10 w-[30rem]">
      <AiOutlineInfoCircle className="text-6xl text-themecolor" />
      <h3 className="text-4xl font-Circular-Bold mt-6 text-center capitalize">
        {title}
      </h3>
      <p className="mb-8 text-center">{message}</p>
      <Button
        className="btn w-max !px-6 !py-4 rounded-xl"
        onClick={async () => {
          window.location.href = `${process.env.REACT_APP_FRONTEND_URL}/${i18n.language}/subscription-plans`;
          setPopup(false);
          setstate1(-1);
        }}
      >
        {t("View Options")}
      </Button>
    </div>
  );
};
export default UpgradePlanModal;
