import React, { useState } from "react";
import { ImFacebook } from "react-icons/im";
import { BsInstagram, BsYoutube } from "react-icons/bs";
import Cookies from "js-cookie";
import { HttpService } from "../../services/base.service";
import { azureService } from "../../services/azure.service";
import { toast } from "react-toastify";
import Popups from "../Model/base";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const [popup, setPopup] = useState(false);
  const [state, setState] = useState(-1);
  const { t, i18n } = useTranslation()

  const handleAuth = () => {
    const session_id = Cookies.get("session_id");
    if (session_id) {
      HttpService.setToken(session_id);
      azureService
        .projectStatus({})
        .then((res) => {
          window.location.href = process.env.REACT_APP_LINK_OPENAI + `/${i18n.language}`;
        })
        .catch((error) => {
          if (error.response?.data?.status === 401) {
            toast.error("Session Expire");
            Cookies.remove("session_id", {
              domain: process.env.REACT_APP_PUBLIC_DOMAIN,
              path: "/",
            });
            window.location.href = `${process.env.REACT_APP_FRONTEND_URL}/${i18n.language}/features/pdf-chat`;
          } else {
            if (error.response?.data?.message.includes("Quota Reached")) {
              setState(11);
              setPopup(true);
            } else {
              setTimeout(() => {
                window.location.href = `${process.env.REACT_APP_FRONTEND_URL}/${i18n.language}/my-project`;
              }, 1500);
            }
            toast.error(error.response?.data?.message);
          }
        });
    } else {
      window.location.href = `${process.env.REACT_APP_FRONTEND_URL}/${i18n.language}/features/pdf-chat`;
    }
  };

  return (
    <footer className="bg-themecolor flex flex-col justify-center items-center">
      <div className="container">
        <div className="py-10 sm:py-14 grid grid-cols-12 gap-y-10 lg:gap-y-0">
          <strong className="col-span-12 lg:col-span-3">
            <a
              href={`${process.env.REACT_APP_FRONTEND_URL}/${i18n.language}`}
              className="cursor-pointer"
            >
              <img
                src="/assets/icons/footer-logo.svg"
                alt="GoPDF"
                className="w-[9rem] h-12 cursor-pointer"
                height={48} width={144}
              />
            </a>
          </strong>
          <div className="col-span-12 sm:col-span-4 lg:col-span-3 flex flex-col gap-y-4 sm:gap-y-6">
            <span className="text-base leading-5 text-white font-bold uppercase">
              {t("Company")}
            </span>
            <ul className="text-white1 font-Circular-Book text-base gap-y-3 flex flex-col font-normal">
              <li>
                <a
                  href={`${process.env.REACT_APP_FRONTEND_URL}/${i18n.language}`}
                  className="cursor-pointer"
                >
                  <span className="cursor-pointer text-white">{t("Home")}</span>
                </a>
              </li>
              <li>
                <a
                  href={`${process.env.REACT_APP_FRONTEND_URL}/${i18n.language}/aboutus`}
                  className="cursor-pointer"
                >
                  <span className="cursor-pointer text-white">{t("About us")}</span>
                </a>
              </li>
            </ul>
          </div>
          <div className="col-span-12 sm:col-span-4 lg:col-span-3 flex flex-col gap-y-4 sm:gap-y-6">
            <span className="text-base leading-5 text-white font-bold uppercase">
              {t("Features")}
            </span>
            <ul className="text-white1 font-Circular-Book text-base gap-y-3 flex flex-col font-normal">
              <li>
                <span
                  onClick={() => {
                    window.location.href = `/${i18n.language}/edit-pdf`;
                  }}
                  className="cursor-pointer"
                >
                  {t("Edit PDF")}
                </span>
              </li>
              <li>
                <span
                  onClick={() => {
                    window.location.href = `/${i18n.language}/fill-and-sign`;
                  }}
                  className="cursor-pointer"
                >
                  {t("Fill & Sign PDF")}
                </span>
              </li>
              <li>
                <span
                  onClick={() => {
                    window.location.href = `/${i18n.language}/search-and-replace`;
                  }}
                  className="cursor-pointer"
                >
                  {t("Search & Replace")}
                </span>
              </li>
              <li>
                <span
                  className="cursor-pointer"
                  onClick={() => {
                    window.location.href = `${process.env.REACT_APP_FRONTEND_URL}/${i18n.language}/features/convert-pdf-to-jpg`;
                  }}
                >
                  {t("Convert PDF To JPG")}
                </span>
              </li>
              <li>
                <span
                  className="cursor-pointer"
                  onClick={() => {
                    window.location.href = `${process.env.REACT_APP_FRONTEND_URL}/${i18n.language}/features/convert-pdf-to-word`;
                  }}
                >
                  {t("Convert PDF To Word")}
                </span>
              </li>
              <li>
                <span
                  className="cursor-pointer"
                  onClick={() => {
                    window.location.href = `${process.env.REACT_APP_FRONTEND_URL}/${i18n.language}/features/convert-html-to-pdf`;
                  }}
                >
                  {t("HTML to PDF")}
                </span>
              </li>
              <li>
                <span
                  className="cursor-pointer"
                  onClick={() => {
                    window.location.href = `${process.env.REACT_APP_FRONTEND_URL}/${i18n.language}/features/header-footer-pdf`;
                  }}
                >
                  {t("Add Header Footer to PDF")}
                </span>
              </li>
              <li>
                <span
                  className="cursor-pointer"
                  onClick={() => {
                    window.location.href = `${process.env.REACT_APP_FRONTEND_URL}/${i18n.language}/features/compress-pdf`;
                  }}
                >
                  {t("Compress PDF")}
                </span>
              </li>
              <li>
                <span
                  className="cursor-pointer"
                  onClick={() => {
                    window.location.href = `${process.env.REACT_APP_FRONTEND_URL}/${i18n.language}/features/merge-pdf`;
                  }}
                >
                  {t("Merge PDF")}
                </span>
              </li>
              
              <li>
                <span
                  className="cursor-pointer"
                  onClick={() => {
                    window.location.href = `${process.env.REACT_APP_FRONTEND_URL}/${i18n.language}/features/protect-pdf`;
                  }}
                >
                  {t("Protect PDF")}
                </span>
              </li>
              <li>
                <span
                  className="cursor-pointer"
                  onClick={() => {
                    window.location.href = `${process.env.REACT_APP_FRONTEND_URL}/${i18n.language}/features/crop-pdf`;
                  }}
                >
                  {t("Crop PDF")}
                </span>
              </li>
              <li>
                <span
                  className="cursor-pointer"
                  onClick={() => {
                    window.location.href = `${process.env.REACT_APP_FRONTEND_URL}/${i18n.language}/features/ocr-pdf`;
                  }}
                >
                  {t("OCR PDF")}
                </span>
              </li>
              <li>
                <span
                  className="cursor-pointer"
                  onClick={() => {
                    window.location.href = `${process.env.REACT_APP_FRONTEND_URL}/${i18n.language}/features/add-pdf-pages`;
                  }}
                >
                  {t("Add Page")}
                </span>
              </li>
              <li>
                <span
                  className="cursor-pointer"
                  onClick={() => {
                    window.location.href = `${process.env.REACT_APP_FRONTEND_URL}/${i18n.language}/features/remove-pdf-pages`;
                  }}
                >
                  {t("Remove Page")}
                </span>
              </li>
              <li>
                <span
                  className="cursor-pointer"
                  onClick={() => {
                    handleAuth();
                  }}
                >
                  {t("AI Chat with PDF")}
                </span>
              </li>
            </ul>
          </div>

          <div className="col-span-12 sm:col-span-4 lg:col-span-3 flex flex-col gap-y-4 sm:gap-y-6">
            <span className="text-base leading-5 text-white font-bold uppercase h-5">
              {t("Help Center")}
            </span>
            <ul className="text-white1 font-Circular-Book text-base gap-y-3 flex flex-col font-normal">
              <li>
                <a
                  href={`${process.env.REACT_APP_FRONTEND_URL}/${i18n.language}/contactus`}
                  className="cursor-pointer"
                >
                  <span className="cursor-pointer text-white">{t("Contact us")}</span>
                </a>
              </li>
              <li>
                <a
                  href={`${process.env.REACT_APP_FRONTEND_URL}/${i18n.language}/privacy`}
                  className="cursor-pointer"
                >
                  <span className="cursor-pointer text-white">
                    {t("Privacy Policy")}
                  </span>
                </a>
              </li>
              <li>
                <a
                  href={`${process.env.REACT_APP_FRONTEND_URL}/${i18n.language}/refund-policy`}
                  className="cursor-pointer"
                >
                  <span className="cursor-pointer text-white">
                    {t("Refund Policy")}
                  </span>
                </a>
              </li>
              <li>
                <a
                  href={`${process.env.REACT_APP_FRONTEND_URL}/${i18n.language}/terms-of-use`}
                  className="cursor-pointer"
                >
                  <span className="cursor-pointer text-white">
                    {t("Terms of Use")}
                  </span>
                </a>
              </li>
              <li>
                <a
                  href={`${process.env.REACT_APP_FRONTEND_URL}/${i18n.language}/FAQs`}
                  className="cursor-pointer"
                >
                  <span className="cursor-pointer text-white">{t("FAQs")}</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="flex items-center pb-4 gap-4">
          <a target="_blank" href="https://play.google.com/store/apps/details?id=io.gopdf" rel="noreferrer"><img src="/assets/app-logo/GooglePlay.svg" alt="Google Play" height={40} width={110} /></a>
          <a target="_blank" href="https://apps.apple.com/us/app/gopdf-io/id6468447409" rel="noreferrer"><img src="/assets/app-logo/AppStore.svg" alt="App Store" height={40} width={110} /></a>
        </div>
        <div className="flex-col flex md:flex-row gap-4 md:gap-0 justify-between items-center border-t-2 border-t-[#6E6ED6] py-6">
          <p className="text-white1 font-normal text-sm leading-4 order-1 md:order-0">
            {t("Copyright © GoPDF. 2023 rights reserved.")}
          </p>
          <ul className="flex items-center gap-x-3 order-0 md:order-1">
            <li className="rounded-full bg-[#8E8EE0] backdrop-blur-md h-7 w-7 flex items-center justify-center">
              <a
                href="https://www.youtube.com/channel/UCl0__r66j63cCFTfxf8gwWQ"
                target="_blank"
                rel="noreferrer"
              >
                <BsYoutube className="text-white" />
              </a>
            </li>
            <li className="rounded-full bg-[#8E8EE0] backdrop-blur-md h-7 w-7 flex items-center justify-center">
              <a
                href="https://instagram.com/gopdf.io?igshid=MzRlODBiNWFlZA=="
                target="_blank"
                rel="noreferrer"
              >
                <BsInstagram className="text-white" />
              </a>
            </li>
            <li className="rounded-full bg-[#8E8EE0] backdrop-blur-md h-7 w-7 flex items-center justify-center">
              <a
                href="https://www.facebook.com/profile.php?id=100086160528733"
                target="_blank"
                rel="noreferrer"
              >
                <ImFacebook className="text-white" />
              </a>
            </li>
          </ul>
        </div>
      </div>
      {state && (
        <Popups
          show={popup}
          hide={setPopup}
          state={state}
          setstate={setState}
          handleStates={null}
          triggerReload={null}
        />
      )}
    </footer>
  );
};

export default Footer;