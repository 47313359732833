import * as React from "react";
const DocumentIcon = ({...props}) => (
    <span className={props.className}>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={30}
            height={30}
            viewBox="0 0 30 30"
            fill="none"
        >
            <path
                d="M27.5 12.5V18.75C27.5 25 25 27.5 18.75 27.5H13.25C7 27.5 4.5 25 4.5 18.75V11.25C4.5 5 7 2.5 13.25 2.5H19.5"
                stroke="white"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M27.4997 12.5H22.4997C18.5 12.5 19.4997 8.25 19.4997 4.5V2.5L27.4997 12.5Z"
                stroke="white"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10.75 16.25H18.25"
                stroke="white"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10.75 21.25H15.75"
                stroke="white"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    </span>
);
export default DocumentIcon;