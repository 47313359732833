import * as React from "react";
const UploadIcon = ({ width, height, className }) => (
    <span className={`${className}`}>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={30}
            height={30}
            viewBox="0 0 30 30"
            fill="none"
        >
            <path
                d="M20.5492 11.125C25.0492 11.5125 26.8867 13.825 26.8867 18.8875L26.8867 19.05C26.8867 24.6375 24.6492 26.875 19.0617 26.875L10.9117 26.875C5.32422 26.875 3.08672 24.6375 3.08672 19.05L3.08672 18.8875C3.08672 13.8625 4.89922 11.55 9.32422 11.1375"
                stroke="white"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15 18.75L15 4.52502"
                stroke="white"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M19.1875 7.3125L15 3.125L10.8125 7.3125"
                stroke="white"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    </span>
);
export default UploadIcon;
