import { Fragment, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import React from 'react';
import { useTranslation } from 'react-i18next';
function LanguageMenu() {
    const { t } = useTranslation()
    const html = document.getElementsByTagName('html')[0].dir

    const languages = [
        {
            name: 'English',
            flag: '/assets/flags/united-kingdom.png',
            locale: 'en',
            href: '/en'
        },
        {
            name: 'Español',
            flag: '/assets/flags/flag.png',
            locale: 'es',
            href: '/es'
        },
        {
            name: 'Français',
            flag: '/assets/flags/france.png',
            locale: 'fr',
            href: '/fr'
        },
        {
            name: 'العربية',
            flag: '/assets/flags/flag-1.png',
            locale: 'ar',
            href: '/ar'
        },
        {
            name: 'Türkçe',
            flag: '/assets/flags/turkey.png',
            locale: 'tr',
            href: '/tr'
        },
        {
            name: '中文 (简体)',
            flag: '/assets/flags/china.png',
            locale: 'zh-cn',
            href: '/zh-cn'
        },
        {
            name: '中文 (繁體)',
            flag: '/assets/flags/china.png',
            locale: 'zh-tw',
            href: '/zh-tw'
        },
        /*
        {
            name: 'Deutsch',
            flag: '/assets/flags/germany.png',
            locale: 'de',
            href: '/de'
        },
        {
            name: 'Italiano',
            flag: '/assets/flags/italy.png',
            locale: 'it',
            href: '/it'
        },
        {
            name: 'Português',
            flag: '/assets/flags/portugal.png',
            locale: 'pt',
            href: '/pt'
        },
        {
            name: '日本語',
            flag: '/assets/flags/puerto-rico.png',
            locale: 'ja',
            href: '/ja'
        },
        {
            name: 'Pусский',
            flag: '/assets/flags/russia.png',
            locale: 'ru',
            href: '/ru'
        },
        {
            name: '한국어',
            flag: '/assets/flags/south-korea.png',
            locale: 'ko',
            href: '/ko'
        },
        
        {
            name: 'Български',
            flag: '/assets/flags/bulgaria.png',
            locale: 'bg',
            href: '/bg'
        },
        {
            name: 'Català',
            flag: '/assets/flags/flag-2.png',
            locale: 'ca',
            href: '/ca'
        },
        {
            name: 'Nederlands',
            flag: '/assets/flags/netherlands.png',
            locale: 'nl',
            href: '/nl'
        },
        {
            name: 'Ελληνικά',
            flag: '/assets/flags/circle.png',
            locale: 'el',
            href: '/el'
        },
        {
            name: 'हिन्दी',
            flag: '/assets/flags/square.png',
            locale: 'hi',
            href: '/hi'
        },
        {
            name: 'Bahasa Indonesia',
            flag: '/assets/flags/indonesia.png',
            locale: 'id',
            href: '/id'
        },
        {
            name: 'Bahasa Melayu',
            flag: '/assets/flags/malaysia.png',
            locale: 'ms',
            href: '/ms'
        },
        {
            name: 'Polski',
            flag: '/assets/flags/poland.png',
            locale: 'pl',
            href: '/pl'
        },
        {
            name: 'Svenska',
            flag: '/assets/flags/sweden.png',
            locale: 'sv',
            href: '/sv'
        },
        {
            name: 'ภาษาไทย',
            flag: '/assets/flags/flag-3.png',
            locale: 'th',
            href: '/th'
        },
        {
            name: 'Українська',
            flag: '/assets/flags/ukraine.png',
            locale: 'uk',
            href: '/uk'
        },
        {
            name: 'Tiếng Việt',
            flag: '/assets/flags/vietnam.png',
            locale: 'vi',
            href: '/vi'
        },
        */
    ]

    const getLanguageFromUrl = () => {
        const path = window.location.pathname;
        const match = path.match(/\/(en-US|en|es|fr|de|it|pt|ja|ru|ko|zh-cn|zh-tw|ar|bg|ca|nl|el|hi|id|ms|pl|sv|th|tr|uk|vi)/);
        return match ? match[1] : "en";
    };
    let index = languages.findIndex((item) => item.locale === getLanguageFromUrl())
    const [selected, setSelected] = useState(languages[index])
    return (
        <Listbox value={selected} onChange={(value) => {
            setSelected(value)
        }}>
            {({ open }) => (
                <>
                    <div className="relative mt-2">
                        <Listbox.Button className="md:w-10 lg:w-9 lg:h-9 xl:w-10 xl:h-10 w-8 md:h-10 h-8 cursor-pointer rounded-full bg-gray-500 text-gray-900 focus:outline-none">
                            <img src={selected?.flag} alt="" className="h-full w-full flex-shrink-0 rounded-full" />
                        </Listbox.Button>

                        <Listbox.Options className="fixed inset-0 h-full w-full bg-black/30 md:!z-[2000] z-40"></Listbox.Options>
                        <Transition
                            show={open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Listbox.Options className={`${html === 'rtl' ? 'left-0 md:left-0' : 'left-0 md:left-auto right-0'} md:absolute fixed bottom-0 md:bottom-auto px-0 md:!z-[2000] z-50 mt-1 py-3.5 md:w-56 w-full md:rounded-xl rounded-t-3xl bg-white text-base shadow-lg focus:outline-none sm:text-sm`}>
                                <h6 className='text-gray-900 font-medium border-b px-4 pb-3.5'>{t("Select Language")}</h6>
                                <div className="flex flex-col gap-2 max-h-56 overflow-auto pt-4 pb-1">
                                    {languages.map((lang) => (
                                        <Listbox.Option
                                            key={lang.name}
                                            className={'relative cursor-pointer select-none'}
                                            value={lang}
                                        >
                                            {({ selected, active }) => (
                                                <a href={`${lang.href}${window.location.pathname.replace(/\/(en-US|en|es|fr|de|it|pt|ja|ru|ko|zh-cn|zh-tw|ar|bg|ca|nl|el|hi|id|ms|pl|sv|th|tr|uk|vi)/, '')}`} className={`${selected ? 'bg-primary-100' : 'bg-transparent hover:bg-gray-100'} flex items-center gap-3 group w-full py-1 px-4`}>
                                                    <div className={`${selected ? 'border-primary-100' : 'border-transparent'} bg-gray-300 border p-0.5 rounded-full relative`}>
                                                        <img src={lang?.flag} alt="" className="h-7 w-7 flex-shrink-0 rounded-full" />
                                                    </div>
                                                    <span
                                                        className={`${selected ? 'text-themecolor font-medium' : 'text-black group-hover:text-primary-100 font-normal'} text-base text-center block truncate`}
                                                    >
                                                        {lang.name}
                                                    </span>
                                                </a>
                                            )}
                                        </Listbox.Option>
                                    ))}
                                </div>
                            </Listbox.Options>
                        </Transition>
                    </div>
                </>
            )
            }
        </Listbox >
    )
}
export default LanguageMenu;