import { Disclosure } from "@headlessui/react"
import { FaMinus, FaPlus } from "react-icons/fa"
import { useTranslation } from "react-i18next";

const FAQStatic = ({ faqs }) => {
    const { t } = useTranslation()


    return (

        <section className="bg-[#F6F6FD] lg:py-[6.25rem] py-[4.25rem]">
            <div className="container">
                <div className="px-2">
                    <h2 className="font-Circular-Black md:text-5xl text-4xl uppercase text-themecolor text-center">
                        {t("Frequently Asked questions")}
                    </h2>
                </div>
                <p className="font-Circular-Bold md:text-3xl text-2xl text-center mt-4 mb-8 sm:mb-16">
                    {t("We are here to answer your questions?")}
                </p>
                <div className="mx-auto !max-w-6xl w-full rounded-2xl md:p-2" id="disclosure">
                    {faqs.map((faq, i) =>
                        <Disclosure key={i}>
                            {({ open }) => (
                                <>
                                    <>
                                        <Disclosure.Button className="flex w-full items-center justify-between rounded-xl border border-[#DDE1E6] p-4 text-left text-sm font-medium focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75 transition-all my-4 text-black font-Circular-Bold">
                                            <h3 className="md:text-2xl text-xl">{faq.question}</h3>
                                            <span className="flex-shrink-0">
                                                {open ?
                                                    <FaMinus /> : <FaPlus />
                                                }
                                            </span>
                                        </Disclosure.Button>
                                        <Disclosure.Panel className="px-4 pt-4 pb-2 md:text-xl text-lg text-gray-500">
                                            {faq.answer}
                                        </Disclosure.Panel>
                                    </>
                                </>
                            )}
                        </Disclosure>
                    )}
                </div>
            </div>
        </section>
    )
}
export default FAQStatic 