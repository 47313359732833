import { PDFUIInstanceContext } from "../components/Editor/FoxitWebPDFContexts";
import { FoxitWebPDFApp } from "../components/Editor/FoxitWebPDFApp";
import { PDFViewerRenderer } from "../components/Editor/PDFViewerRenderer";
import EditorLayoutModule from "../components/FoxitEditorModule/EditorLayoutModule";
import { toast } from "react-toastify";
import Button from "../components/Button/Button";
import { Helmet } from "react-helmet";
import { getPageMetadata } from "../metadata";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { useEffect } from "react";


function FoxitEditor({ file }) {
  const { t } = useTranslation()
  
  const { title, description } = getPageMetadata("");
  const jsonLd =
    { "@context": "https://schema.org", "@type": "Product", "brand": "gopdf", "name": t("Online PDF Editor"), "description": "Add text, shapes, images, annotations and signs to your PDF", "category": "Productivity", "aggregateRating": { "@type": "AggregateRating", "worstRating": "1", "bestRating": "5", "ratingValue": "4.4", "ratingCount": "24" } }
  return (
    <div className="App">
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta name="language" content="English"/>
        <meta property="og:image" content="https://gopdf.s3.eu-central-1.amazonaws.com/Logo+go+pdf.svg" />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLd) }}
        />
        <meta httpEquiv="Cache-Control" content="max-age=2592000, must-revalidate" />
        <meta name="robots" content="index, follow"/>
      </Helmet>
      <Helmet
        link={[
          { rel: 'canonical', href: window.location.href }
        ]}
      />

      <EditorLayoutModule
        heading={t("Online PDF Editor")}
        subHeading={t("Edit PDF files for free. Fill & sign PDF")}
      />
      <FoxitWebPDFApp file={file}>
        <PDFViewerRenderer />
        <PDFUIInstanceContext.Consumer>
          {(pdfui) => {
            if (file && pdfui) {
              pdfui.openPDFByFile(file);
            }

            const handleBlankPage = async () => {
              try {
                pdfui.getPDFDocRender().then(async (document) => {
                  if (document) {
                    const PDFDoc = document.getPDFDoc();
                    const pageCount = PDFDoc.getPageCount();
                    const page1 = await PDFDoc.getPageByIndex(0);
                    const width = await page1.getWidth();
                    const height = await page1.getHeight();
                    await PDFDoc.insertPage(pageCount, width, height);
                  } else {
                    toast.error("Kindly upload or create new document");
                  }
                });
              } catch (error) {
                toast.error("Kindly upload or create new document");
              }
            };

            return (
              <div className="flex justify-center py-4">
                <Button
                  className="py-4 !w-[fit-content] px-[5.875rem] rounded-[10px]"
                  onClick={handleBlankPage}
                >
                  {t("New Blank Page")}
                </Button>
              </div>
            );
          }}
        </PDFUIInstanceContext.Consumer>
        <PDFUIInstanceContext.Consumer>
          {(pdfui) => {
            return <></>;
          }}
        </PDFUIInstanceContext.Consumer>
      </FoxitWebPDFApp>
    </div>
  );
}

export default FoxitEditor;
