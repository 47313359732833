import * as React from "react";
const ImageIcon = ({...props}) => (
    <span className={props.className}>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={30}
            height={30}
            viewBox="0 0 30 30"
            fill="none"
            
        >
            <path
                d="M11.25 13.4375C9.35 13.4375 7.8125 11.9 7.8125 10C7.8125 8.1 9.35 6.5625 11.25 6.5625C13.15 6.5625 14.6875 8.1 14.6875 10C14.6875 11.9 13.15 13.4375 11.25 13.4375ZM11.25 8.4375C10.3875 8.4375 9.6875 9.1375 9.6875 10C9.6875 10.8625 10.3875 11.5625 11.25 11.5625C12.1125 11.5625 12.8125 10.8625 12.8125 10C12.8125 9.1375 12.1125 8.4375 11.25 8.4375Z"
                fill="white"
            />
            <path
                d="M18.75 28.4375H11.25C4.4625 28.4375 1.5625 25.5375 1.5625 18.75V11.25C1.5625 4.4625 4.4625 1.5625 11.25 1.5625H16.25C16.7625 1.5625 17.1875 1.9875 17.1875 2.5C17.1875 3.0125 16.7625 3.4375 16.25 3.4375H11.25C5.4875 3.4375 3.4375 5.4875 3.4375 11.25V18.75C3.4375 24.5125 5.4875 26.5625 11.25 26.5625H18.75C24.5125 26.5625 26.5625 24.5125 26.5625 18.75V12.5C26.5625 11.9875 26.9875 11.5625 27.5 11.5625C28.0125 11.5625 28.4375 11.9875 28.4375 12.5V18.75C28.4375 25.5375 25.5375 28.4375 18.75 28.4375Z"
                fill="white"
            />
            <path
                d="M26.5625 7.1875H19.6875C19.175 7.1875 18.75 6.7625 18.75 6.25C18.75 5.7375 19.175 5.3125 19.6875 5.3125H26.5625C27.075 5.3125 27.5 5.7375 27.5 6.25C27.5 6.7625 27.075 7.1875 26.5625 7.1875Z"
                fill="white"
            />
            <path
                d="M23.125 10.625C22.6125 10.625 22.1875 10.2 22.1875 9.6875V2.8125C22.1875 2.3 22.6125 1.875 23.125 1.875C23.6375 1.875 24.0625 2.3 24.0625 2.8125V9.6875C24.0625 10.2 23.6375 10.625 23.125 10.625Z"
                fill="white"
            />
            <path
                d="M3.33762 24.625C3.03762 24.625 2.73762 24.475 2.56262 24.2125C2.27512 23.7875 2.38762 23.2 2.81262 22.9125L8.97512 18.775C10.3251 17.875 12.1876 17.975 13.4126 19.0125L13.8251 19.375C14.4501 19.9125 15.5126 19.9125 16.1251 19.375L21.3251 14.9125C22.6626 13.775 24.7376 13.775 26.0751 14.9125L28.1126 16.6625C28.5001 17 28.5501 17.5875 28.2126 17.9875C27.8751 18.375 27.2876 18.425 26.8876 18.0875L24.8501 16.3375C24.2251 15.8 23.1626 15.8 22.5501 16.3375L17.3501 20.7999C16.0251 21.9374 13.9376 21.9374 12.6001 20.7999L12.1876 20.4375C11.6126 19.95 10.6626 19.9 10.0251 20.3375L3.87512 24.475C3.70012 24.575 3.51262 24.625 3.33762 24.625Z"
                fill="white"
            />
        </svg>
    </span>
);
export default ImageIcon;