import { useSelector } from "react-redux";
import * as yup from "yup";
//
yup.addMethod(yup.string, "firstnameString", function () {
  let user = useSelector((state) => state.auth.user);

  return this.transform((value) => (value === "" ? undefined : value));
});
yup.addMethod(yup.string, "lastnameString", function () {
  let user = useSelector((state) => state.auth.user);

  return this.transform((value) => (value === "" ? user.lastName : value));
});

export const validateSignupForm = yup.object().shape({
  fullName: yup
    .string()
    .required("Name is required")
    .matches(/^[A-Za-z ]+$/i, "Invalid Name"),
  email: yup
    .string()
    .matches(
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      "Invalid email address"
    ),
  password: yup
    .string()
    .required("Password is required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#.$%^?&*()-])[A-Za-z\d!@#.$%^?&*()-]{8,}$/,
      "Password must contain 1 uppercase 1 special character and 1 number and 8 characters long"
    ),
});

export const validateLoginForm = yup.object().shape({
  email: yup
    .string()
    .matches(
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      "Invalid email address"
    ),
  password: yup.string().required("Password is required"),
  // .matches(
  //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#.$%^?&*()-])[A-Za-z\d!@#.$%^?&*()-]{8,}$/,
  //   "Password must contain 1 uppercase 1 special character and 1 number and 8 characters long"
  // ),
});

export const validateEditForm = yup.object().shape({
  firstName: yup.string().required("First Name is required"),

  lastName: yup.string().required("Last Name is required"),

  countryCode: yup.string().notRequired().length(12),
});

export const validateloginForm = yup.object().shape({
  email: yup
    .string()
    .matches(
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      "Invalid email address"
    ),
  password: yup.string().required("Password is required"),
});

export const validateForgetpasswordForm = yup.object().shape({
  email: yup
    .string()
    .matches(
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      "Invalid email address"
    ),
});

export const validateResetpasswordForm = yup.object().shape({
  code: yup
    .number()
    .typeError("Verification Code")
    .required()
    .label("Verification Code"),
  password: yup.string().required().min(8).label("Password"),
  confirm_password: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

export const validateCreatorForm = yup.object().shape({
  // website: yup.string().required("Website is required"),
  //  website: yup.string().required("Website is required"),
  // bio: yup.string().required("First Name is required"),
  // // .matches(/^[A-Za-z ]+$/i, "Invalid Name"),
  // lastName: yup.string().required("Last Name is required"),
  // // .matches(/^[A-Za-z ]+$/i, "Invalid Name"),
  // email: yup
  //   .string()
  //   .matches(
  //     /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
  //     "Invalid website address"
  //   ),
  // password: yup.string().required("password is required"),
  // // phone: yup.number().required("Number is required"),
  // countryCode:yup.string()
});

export const validateOTPForm = yup.object().shape({
  OTP: yup.string().matches(/^[0-9]$/i, "Invalid OTP"),
});
