import { useTranslation } from "react-i18next";
import Button from "../Button/Button";
import { HiOutlineArrowRight } from "react-icons/hi"
const VideoBanner = ({ title, description, buttonTitle }) => {
  const { i18n } = useTranslation()
  const html = document.getElementsByTagName('html')[0].dir
  return (
    <section className="container flex flex-col xl:flex-row gap-8 sm:gap-32 md:py-[8rem] py-20 items-center text-center" id="videoBanner">
      <div className="xl:w-1/2">
        <h1 className="text-3xl xl:text-5xl md:text-left text-center font-Circular-Black 3xl:min-w-[37.375rem]">{title}</h1>
        <p className="max-w-51 mt-4 md:text-left text-center font-Circular-Medium">{description}</p>
        <div className="w-full flex md:justify-start justify-center">
          <Button
            onClick={() => {
              window.location.href = `/${i18n.language}`;
            }}
            className="border rounded-full border-white w-max py-2 px-4 md:!py-5 sm:!px-7 mt-4 sm:mt-10 capitalize gap-8"
          >
            {buttonTitle}
            <span className={`${html === 'rtl' && 'rotate-180'} bg-white rounded-full flex justify-center items-center h-9 w-9`}>
              <HiOutlineArrowRight color="#5E5ED2" />
            </span>
          </Button>
        </div>
      </div>
      <div className="xl:w-1/2 h-80 sm:h-[35.375rem] w-full">
        <iframe
          className="rounded-3xl flex-shrink-0"
          width="100%"
          height="100%"
          src="https://www.youtube.com/embed/KZUNpNFCF2U"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </div>

    </section>
  );
};
export default VideoBanner;
