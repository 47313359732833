import { HttpService } from "./base.service";

class AuthService extends HttpService {
  prefix = "user";

  /**
   * User
   * @paramdata
   */
  auth = (data) => this.get(`${this.prefix}/auth`, data);
  login = (data) => this.post(`${this.prefix}/login`, data);
  loginOTPVerify = (data) => this.post(`${this.prefix}/login_otp_verify`, data);
  twoFALogin = (data) => this.post(`${this.prefix}/2fa-login`, data);
  resendOTP = (data) => this.post(`${this.prefix}/resend-otp`, data);
  socialLogin = (data) => this.post(`${this.prefix}/social_login`, data);
  forgotPassword = (data) => this.post(`${this.prefix}/forgetPassword`, data);
  resetPassword = (data) => this.post(`${this.prefix}/reset-password`, data);
  otpVerify = (data) => this.post(`${this.prefix}/verifyOTP`, data);
  signup = (data) => this.post(`${this.prefix}/signup`, data);
  logout = () => this.get(`${this.prefix}/logout`);
}

export const authService = new AuthService();
