import { useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

const Input = ({
  placeholder,
  className,
  name,
  type,
  register,
  onChange,
  value,
  disabled,
  ...rest
}) => {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <div className="relative h-full">
      <input
        id="input"
        onChange={onChange}
        name={name}
        {...(register !== undefined && { ...register(name) })}
        type={
          type
            ? type === "password"
              ? showPassword
                ? "text"
                : type
              : type
            : "text"
        }
        className={`input-text ${className} px-3 py-4 text-base text-black w-full bg-transparent border border-grey 
        rounded-md outline-none`}
        required
        {...rest}
        placeholder={placeholder ? placeholder : ""}
        value={value}
        disabled={disabled ? disabled : ""}
      />
      {type === "password" ? (
        !showPassword ? (
          <AiOutlineEye
            onClick={() => setShowPassword(true)}
            className="cursor-pointer absolute top-5 right-3 h-5 w-5"
          />
        ) : (
          <AiOutlineEyeInvisible
            onClick={() => setShowPassword(false)}
            className="cursor-pointer absolute top-5 right-3 h-5 w-5"
          />
        )
      ) : (
        ""
      )}
    </div>
  );
};

export default Input;
