import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./icomoon/style.css";
import App from "./App";
import "./lang.css";

// i18n.js
import i18n from 'i18next';
import { I18nextProvider, initReactI18next } from 'react-i18next';
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: "en",
        debug: false,
        interpolation: {
            escapeValue: false,
        },
    });

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <I18nextProvider i18n={i18n}>
        <App i18n={i18n} />
    </I18nextProvider>
);
