import React, { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PinField from "react-pin-field";
import { authService } from "../../services/auth.service";
import Button from "../Button/Button";
import { useDispatch } from "react-redux";
import { setResetPin } from "../../redux/slices/userSlice";
import { useTranslation } from "react-i18next";


const OTPVerifyModal = ({ setstate1, email }) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false);
  const [pin, setPin] = useState("");
  const { t } = useTranslation()

  const onSubmit = async (code) => {
    try {
      setLoading(true);
      const res = await authService.otpVerify({
        pin: parseInt(code),
        email: email,
      });
      //   console.log(res);
      if (res.status === 200 || res.status === 201) {
        dispatch(setResetPin(parseInt(code)))
        setLoading(false);
        setstate1(7);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      } else {
        console.log(error.response);
      }
    }
  };

  return (
    <div className="sm:w-[36.688rem] xs3:w-full  w-[31rem] py-10 xl:px-20 sm:px-14 px-8 m-auto rounded-lg">
      <div className="flex flex-col gap-10">
        <div className="flex flex-col sm:gap-16 gap-10">
          <div className="flex justify-center items-center">
            <svg
              className="w-[9.25rem] h-[2.188rem]"
              viewBox="0 0 148 35"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M32.258 34V17.026H15.974V22.546H26.278C25.91 24.846 23.564 28.894 17.538 28.894C12.064 28.894 7.234 25.076 7.234 17.716C7.234 9.988 12.57 6.63 17.492 6.63C22.782 6.63 25.496 9.988 26.324 12.886L32.396 10.724C30.786 5.71 26.048 0.695998 17.492 0.695998C8.66 0.695998 0.794 7.182 0.794 17.716C0.794 28.25 8.292 34.69 17.078 34.69C22 34.69 25.22 32.482 26.692 30.274L27.106 34H32.258ZM48.3695 29.124C45.3795 29.124 42.6195 26.916 42.6195 22.684C42.6195 18.406 45.3795 16.244 48.3695 16.244C51.3595 16.244 54.1195 18.406 54.1195 22.684C54.1195 26.962 51.3595 29.124 48.3695 29.124ZM48.3695 10.678C41.6075 10.678 36.5015 15.692 36.5015 22.684C36.5015 29.63 41.6075 34.69 48.3695 34.69C55.1315 34.69 60.2375 29.63 60.2375 22.684C60.2375 15.692 55.1315 10.678 48.3695 10.678ZM71.9208 16.244V6.906H76.9348C80.1088 6.906 82.0408 8.7 82.0408 11.598C82.0408 14.404 80.1088 16.244 76.9348 16.244H71.9208ZM77.7168 21.718C84.1568 21.718 88.4348 17.486 88.4348 11.552C88.4348 5.664 84.1568 1.386 77.7168 1.386H65.5268V34H71.8748V21.718H77.7168ZM99.6365 28.112V7.274H104.743C110.079 7.274 114.495 10.632 114.495 17.716C114.495 24.8 110.033 28.112 104.697 28.112H99.6365ZM104.927 34C114.219 34 121.073 28.02 121.073 17.716C121.073 7.412 114.265 1.386 104.973 1.386H93.2885V34H104.927ZM132.97 34V21.304H145.62V15.462H132.924V7.412H147.184V1.386H126.576V34H132.97Z"
                fill="#5E5ED2"
              />
            </svg>
          </div>
          <div className="flex flex-col gap-3 text-center">
            <h3 className="text-[1.75rem] leading-7">{t("OTP Verification")}</h3>
            <p className="leading-none">
              {t("Please enter the 4 digit code we have sent you.")}
            </p>
          </div>
        </div>

        <div className="flex flex-col gap-10">
          <div className="flex w-full gap-4 max-w-xs mx-auto">
            <PinField
              type="number"
              length={4}
              validate={/^[0-9]$/}
              onChange={async (pin) => {
                setPin(pin);
                if (pin && pin.length === 4) {
                  onSubmit(pin);
                }
              }}
              className="bg-transparent appearance-none overflow-y-auto border-b-2 py-3 text-center lg:text-4xl md:text-3xl sm:text-2xl text-xl font-Avenir-SemiBold  outline-none border-darkgrey"
            />
          </div>

          <Button
            isLoading={loading}
            className="py-[1.125rem] bg-themecolor rounded-[10px]"
            onClick={() => {
              onSubmit(pin);
            }}
          >
            {t("Verify OTP")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default OTPVerifyModal;
