import { useTranslation } from "react-i18next";

const StatsBanner = ({ title }) => {
  const { t } = useTranslation();

  const stats = [
    {
      title: t("80k+"),
      description: t("PDFs added per month"),
    },
    {
      title: t("43k%"),
      description: t("PDFs Protected per month"),
    },
    {
      title: t("2M"),
      description: t("PDFs edited per month"),
    },
    {
      title: t("140k+"),
      description: t("PDFs downloaded per month"),
    },
  ];
  return (
    <section className="bg-grey-100 py-20 sm:py-28 md:py-40">
      <div className="container flex flex-col justify-center items-center text-center">
        {/* <h2 className="md:text-5xl text-4xl text-black1 max-w-[52.625rem] sm:leading-15 font-Circular-Black">
          {title ? title : t("Users trust to manage PDFs on GoPDF platform")}
        </h2> */}

        <h2 aria-level={2} className="md:text-5xl text-4xl text-black1 max-w-[52.625rem] sm:leading-15 font-Circular-Black">
          {title}
        </h2>
        <ul className="container !max-w-6xl mx-auto flex-wrap gap-8 sm:gap-0 sm:flex-nowrap flex justify-center sm:justify-between w-full mt-16">
          {stats.map((stat, i) => (
            <li key={i}>
              <h3 aria-level={3} className="xl:text-7xl text-5xl font-Circular-Black">{stat.title}</h3>
              <p>{stat.description}</p>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};
export default StatsBanner;
