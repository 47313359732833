const local = {
  API_ENDPOINT: "http://localhost:3032",
};
const dev = {
  API_ENDPOINT: "https://dev-api.gopdf.io",
};
const prod = {
  API_ENDPOINT: "https://api.gopdf.io",
};
const mobi = {
  API_ENDPOINT: "https://mobi-api.gopdf.io",
};



const config = {
  ...(process.env.REACT_APP_STAGE === "local"
    ? local
    : process.env.REACT_APP_STAGE === "dev"
    ? dev
    : process.env.REACT_APP_STAGE === "mobi"
    ? mobi
    :prod),
};

export default config;