import * as React from "react";
const EditIcon = ({ width, height, className }) => (
    <span className={`${className}`}>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={30}
            height={30}
            viewBox="0 0 30 30"
            fill="none"
        >
            <path
                d="M13.75 2.5H11.25C5 2.5 2.5 5 2.5 11.25V18.75C2.5 25 5 27.5 11.25 27.5H18.75C25 27.5 27.5 25 27.5 18.75V16.25"
                stroke="white"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M20.0494 3.77495L10.1994 13.6249C9.82436 13.9999 9.44936 14.7375 9.37436 15.275L8.83686 19.0374C8.63686 20.3999 9.59936 21.35 10.9619 21.1625L14.7244 20.6249C15.2494 20.5499 15.9869 20.1749 16.3744 19.7999L26.2244 9.94995C27.9244 8.24995 28.7244 6.27495 26.2244 3.77495C23.7244 1.27495 21.7494 2.07495 20.0494 3.77495Z"
                stroke="white"
                strokeWidth={1.5}
                strokeMiterlimit={10}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M18.6367 5.1875C19.4742 8.175 21.8117 10.5125 24.8117 11.3625"
                stroke="white"
                strokeWidth={1.5}
                strokeMiterlimit={10}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    </span>
);
export default EditIcon;
