const FilesUploadedPng = "https://gopdf.s3.eu-central-1.amazonaws.com/edit-pdf/files-uploaded.png"
const EditPdfText = "https://gopdf.s3.eu-central-1.amazonaws.com/edit-pdf/edit-pdf-img.png"
const AddPdfText = "https://gopdf.s3.eu-central-1.amazonaws.com/edit-pdf/add-images.png"
const FillPdfForm = "https://gopdf.s3.eu-central-1.amazonaws.com/edit-pdf/edit-pdf-add.png"
const InsertLink = "https://gopdf.s3.eu-central-1.amazonaws.com/edit-pdf/create-link.png"
const ConcealContent = "https://gopdf.s3.eu-central-1.amazonaws.com/edit-pdf/add-shape-rectangle.png"
const AddShapes = "https://gopdf.s3.eu-central-1.amazonaws.com/edit-pdf/add-shape.png"
const SearchAndReplace = "https://gopdf.s3.eu-central-1.amazonaws.com/edit-pdf/find-and-replace.png"
const AddFormYields = "https://gopdf.s3.eu-central-1.amazonaws.com/edit-pdf/add-form.png"
const AnalyzePdf = "https://gopdf.s3.eu-central-1.amazonaws.com/edit-pdf/analyze-pdf.png"
const SaveEdits = "https://gopdf.s3.eu-central-1.amazonaws.com/edit-pdf/download-pdf.png"
export{
    FilesUploadedPng,
    EditPdfText,
    AddPdfText,
    FillPdfForm,
    InsertLink,
    ConcealContent,
    AddShapes,
    SearchAndReplace,
    AddFormYields,
    AnalyzePdf,
    SaveEdits
}