import React from "react";
import Button from "../Button/Button";
import { useTranslation } from "react-i18next";

const CongratulationModal = ({ setstate1 }) => {
  const {t} = useTranslation();
  return (
    <div className="w-[26rem] p-10 m-auto rounded-lg">
      <div className="flex flex-col justify-center items-center">
        <svg
          className="mb-5"
          width="90"
          height="90"
          viewBox="0 0 90 90"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle opacity="0.2" cx="45" cy="45" r="45" fill="#5E5ED2" />
          <path
            d="M63.8588 30.4701C63.2703 29.8807 62.3156 29.8797 61.7271 30.4676L43.6417 48.5053L37.1166 41.4183C36.5528 40.8063 35.5995 40.7666 34.9864 41.3304C34.3738 41.8942 34.3346 42.8479 34.8985 43.4605L42.4864 51.7012C42.7643 52.0033 43.1532 52.1786 43.5633 52.1872C43.5743 52.1876 43.5849 52.1876 43.5955 52.1876C43.994 52.1876 44.3774 52.0294 44.6598 51.7479L63.8558 32.6022C64.4457 32.0143 64.4467 31.0596 63.8588 30.4701Z"
            fill="#5E5ED2"
          />
          <path
            d="M63.4924 43.4924C62.6598 43.4924 61.9849 44.1673 61.9849 45C61.9849 54.3659 54.3659 61.9849 45 61.9849C35.6347 61.9849 28.0151 54.3659 28.0151 45C28.0151 35.6347 35.6347 28.0151 45 28.0151C45.8327 28.0151 46.5076 27.3402 46.5076 26.5076C46.5076 25.6748 45.8327 25 45 25C33.9719 25 25 33.9719 25 45C25 56.0277 33.9719 65 45 65C56.0277 65 65 56.0277 65 45C65 44.1673 64.3252 43.4924 63.4924 43.4924Z"
            fill="#5E5ED2"
          />
        </svg>

        <p className="text-center mb-1">{t("Congratulation")} </p>
        <p className="text-center mb-8">
          {" "}
          {t("Password has been reset successfully")}
        </p>

        <Button
          className=" py-[1.125rem] w-[9.375rem] rounded-[10px]"
          onClick={() => {
            setstate1();
          }}
        >
          {t("OK")}
        </Button>
      </div>
    </div>
  );
};

export default CongratulationModal;
