
const ImgGenerator = (url) => {
    let parts = url.split("/en/")
    let newPath = window.location.pathname.split("/")[1];
    switch (newPath) {
        case 'en': return parts[0] + "/en/" + parts[1]
        case 'es': return parts[0] + "/es/" + parts[1]
        case 'fr': return parts[0] + "/fr/" + parts[1]
        case 'ar': return parts[0] + "/ar/" + parts[1]
        case 'tr': return parts[0] + "/tr/" + parts[1]
        case 'zh-cn': return parts[0] + "/zh-cn/" + parts[1]
        case 'zh-tw': return parts[0] + "/zh-tw/" + parts[1]
        default: return url
    }
}
export {
    ImgGenerator
}