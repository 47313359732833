import * as React from "react";
const EyeIcon = ({ width, height, className }) => (
    <span className={`${className}`}>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={30}
            height={30}
            viewBox="0 0 30 30"
            fill="none"
            
        >
            <path
                d="M25.9064 10.8804C26.9688 12.2607 27.5 12.9508 27.5 15C27.5 17.0492 26.9688 17.7393 25.9064 19.1196C23.785 21.8756 20.2274 25 15 25C9.77264 25 6.21495 21.8756 4.09361 19.1196C3.0312 17.7393 2.5 17.0492 2.5 15C2.5 12.9508 3.0312 12.2607 4.09361 10.8804C6.21495 8.12445 9.77264 5 15 5C20.2274 5 23.785 8.12445 25.9064 10.8804Z"
                stroke="white"
                strokeWidth={1.5}
            />
            <path
                d="M11.25 15C11.25 12.9289 12.9289 11.25 15 11.25C17.0711 11.25 18.75 12.9289 18.75 15C18.75 17.0711 17.0711 18.75 15 18.75C12.9289 18.75 11.25 17.0711 11.25 15Z"
                stroke="white"
                strokeWidth={1.5}
            />
        </svg>
    </span>
);
export default EyeIcon;
