import React from "react";
import UploadBanner from "../components/features/UploadBanner";
import DescriptionBanner from "../components/features/DescriptionBanner";
import StatsBanner from "../components/features/StatsBanner";
import ProcedureBanner from "../components/features/ProcedureBanner";
import SearchIcon from "../components/icons/ShearchIcon";
import { Helmet } from "react-helmet";
import { getPageMetadata } from "../metadata";
import { useTranslation } from "react-i18next";
import { ImgGenerator } from "../frontend.services";

function SearchAndReplace({ file, setFile }) {
  const { t } = useTranslation()

  const { title, description } = getPageMetadata("searchAndReplace");
  const steps = [
    {
      title: t("Search & Replace Text in Your PDF File"),
      description:
        t("You can easily upload your PDF files and you can quickly search for specific text or replace certain words in PDF files."),
      icon: (
        <SearchIcon className="h-10 w-10 md:h-16 md:w-16 p-1 bg-themecolor rounded-full flex justify-center items-center" />
      ),
      img: ImgGenerator("https://gopdf.s3.eu-central-1.amazonaws.com/goPdf/en/search-and-replace.webp"),
    },
  ];
  const uploadBannerTitle = t("Search & Replace PDF Files");
  const uploadBannerDescription =
    t("GoPDF free online PDF editor to search and replace PDF files. With our user-friendly interface, you can easily upload your PDF files and begin search and replace PDF files right away.");
  const procedureTitle = t("How to Search & Replace Files");
  const procedureDescription =
    t("With GoPDF, you can easily search and replace text in PDF files.");
  const jsonLd =
    { "@context": "https://schema.org", "@type": "Product", "brand": "gopdf", "name": t("Online PDF Editor"), "description": "Add text, shapes, images, annotations and signs to your PDF", "category": "Productivity", "aggregateRating": { "@type": "AggregateRating", "worstRating": "1", "bestRating": "5", "ratingValue": "4.4", "ratingCount": "24" } }
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta name="language" content="English" />
        <meta property="og:image" content="https://gopdf.s3.eu-central-1.amazonaws.com/Logo+go+pdf.svg" />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLd) }}
        />
        <meta httpEquiv="Cache-Control" content="max-age=2592000, must-revalidate" />
        <meta name="robots" content="index, follow"/>

      </Helmet>
      <Helmet
        link={[
          { rel: 'canonical', href: window.location.href }
        ]}
      />

      <UploadBanner
        title={uploadBannerTitle}
        description={uploadBannerDescription}
        file={file}
        setFile={setFile}
      />
      <DescriptionBanner />
      <StatsBanner />
      <ProcedureBanner
        title={procedureTitle}
        description={procedureDescription}
        steps={steps}
      />
    </>
  );
}

export default SearchAndReplace;
