(function (root, factory) {
  if (typeof exports === "object" && typeof module === "object") {
    module.exports = factory();
  } else if (typeof define === "function" && define.amd) {
    define([], factory);
  } else {
    var a = factory();
    for (var i in a) (typeof exports === "object" ? exports : root)[i] = a[i];
  }
})(self, function () {
  return {
    licenseSN: "Bauh9k7foiDeT9DOtXDHe1R6IiU7aOA+j9nZIinxJEDglYxlWKkHxw==",
    licenseKey:
      "PjwcmTRQMS6sb/teo9dUcD5R3hEznQ90xoskHA4RzhHsPYN3rheePn52VA3v4B2yTSLvgVJp0zF7wYbEY35WKuiChE8MRrFOFSuOJ2J++7Fg7Jcpj8mBj0BxG+ZQ/1/FTdlCKTtkbR9m3pGNczn8L4oW9i/3eH6E1BsaW0dTr0OCsvuGASolO2ybIv8GITkn0m7+uYd/9frMWkNUNn0XQ9PfmNwtsBvGI9whpn/Kjhog6Pt8UF1NVHWrt2/h+jSIMzmhT678AgyRHnPdM/1RTrOZ3aJmGbf3mX+mKXYSU8B4XOmgirgSuBM6NpvHvyVMAFnhvRuj3TYE86a91h3jMf3Xxd5UyIUZQiFcbtCo0rIifpWxSbJyNMq/+j1wqEZRBhb37WA+BmjCrJ6Fk/fOQaW9VNDqLzRZNYUE+trlFI9IsHA9FWAFsn3VMKxV+sj16NNOnJsup7ezmxdYTV4CalQEhpMwViOoA1YPGYAKy8G9AT5c3kuyegUO6076Vu3nLREkeAFQlJjvSw7EpNL27kXgC83zEl8N/CJyEnQEGzStJVzolqXufJr3zjUt5A89m7JrYWqbLIKfrTAlPI6gnIBtLEatN4UvwRGbsdbxtUXmogOb4zBtxo6gT4WWnYB/vx2reUbfRbTBUV7rKOScmXusmNZaK0Q40g8JxkaBNia5yR9JtLUuGjiTJc7n0vzVuh/0Hnh3nnxsFOKETEQxL5GEyk0Cu2XKdftRhY6y4JQ3RgoULpp8XCJSnTECxsVxol+Vv9xL1XTZ8xtw2YAVLZBqohvnSg+jkuc1O0VcVTli1eb3WD1zIb9TPmXJfJqJnp/GyXfwGl8/Uug2E8caeTAVIS51JP13d23jBueo/a+BTMaIm8jmWZM5zD9nGYh5VvVB4LuMbGHcz/oIAM9JlgdvIaNWYWkjZxRrPDil6NYyhiHdjDMxglrReuaRz++TRVhzszOcPwD9HPkd4gx10nppOiFnxv7fFsybQ228OlFF71M775i7l5urS8uPcB2FWeYt/Po0GaHARUn2UWhHKkCNt8zEwLyJ+Zoj5a3D8PeMqTShKR/ALZ7ZZjp8Aw8bRfk8cuGemcA7g2evNJrdGkYbIWGtyYyJ/UCYkkvv/4tf08IF+06+pGr/sNW2gqdOt2UO5fYoHVmNEE+7DYShDh/MaIETP0vKmLxENe9vWpUxwtDk94Qd+DBQjtEvii2cI9YqITBYJUNUK3bHMG9ae9X8RBaUDKVw+ghZ2NMhiRv69CvkQfZpD3KhUw==",
  };
});
