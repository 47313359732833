import React from "react";
import DescriptionBanner from "../components/features/DescriptionBanner";
import StatsBanner from "../components/features/StatsBanner";
import ProcedureBanner from "../components/features/ProcedureBanner";
import QuillSignature from "../components/icons/QuillSignature";
import VideoBanner from "../components/features/VideoBanner";
import { getPageMetadata } from "../metadata";
import { Helmet } from "react-helmet";
import UploadIcon from "../components/icons/UploadIcon";
import TextIcon from "../components/icons/textIcon";
import ImageIcon from "../components/icons/imageIcon";
import LinkIcon from "../components/icons/linkIcon";
import ShapesIcon from "../components/icons/shapesIcon";
import DocumentIcon from "../components/icons/documentIcon";
import LogoutIcon from "../components/icons/logoutIcon";
import {
  AddFormYields,
  AddPdfText,
  AddShapes,
  AnalyzePdf,
  ConcealContent,
  EditPdfText,
  FilesUploadedPng,
  FillPdfForm,
  InsertLink,
  SaveEdits,
  SearchAndReplace,
} from "../imports.images";
import FAQStatic from "../components/Faq/Faq";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ImgGenerator } from "../frontend.services";

function EditPDF() {
  const { title, description } = getPageMetadata("editPdf");
  const { t, i18n } = useTranslation()
  const steps = [
    {
      title: t("Easy file upload"),
      description:
        t("Uploading PDF files is also easy with GoPFD. This also means that transmission is encrypted so as to provide secure transfer of your files, even up to delivery. You can rest assured that after you are done with work, the storage of data as files will be forever erased. It is possible to move the files straight to the page or upload them on your PC by clicking on the “Upload” option. The management of documents is very easy with GoPDF. Adding directly to text in PDFs, type on PDF To start, click on the ‘Text’ tool in the toolbar, and then click on the PDF page."),
      icon: (
        <LogoutIcon className="h-14 w-14 md:h-16 md:w-16 p-1 flex-shrink-0 bg-themecolor rounded-full flex justify-center items-center border-8 border-white" />
      ),
      img: ImgGenerator("https://gopdf.s3.eu-central-1.amazonaws.com/goPdf/en/file-uploaded.webp"),
      alt: "Online PDF Editor Tool",
    },
    {
      title: t("Edit Pdf Text"),
      description:
        t("A change of font in the PDF document can be performed using the ‘Text’ option provided in the top toolbar. Pressing on existing text starts editing. Change the attributes of your text, such as boldness, italics, font size, family, or color."),
      icon: (
        <TextIcon className="h-14 w-14 md:h-16 md:w-16 p-1 flex-shrink-0 bg-themecolor rounded-full flex justify-center items-center border-8 border-white" />
      ),
      img: ImgGenerator("https://gopdf.s3.eu-central-1.amazonaws.com/goPdf/en/edit-pdf-text.webp"),
      alt: "Add shapes to PDF file",
    },
    {
      title: t("Add an image to a PDF"),
      description:
        t("Locate the ‘Images’ menu, click ‘New Image', and add a picture from your PC. Position it on the document’s page by moving the mouse over the icon. Drag the image corners to enlarge or compress them."),
      icon: (
        <ImageIcon className="h-14 w-14 md:h-16 md:w-16 p-1 flex-shrink-0 bg-themecolor rounded-full flex justify-center items-center border-8 border-white" />
      ),
      img: ImgGenerator("https://gopdf.s3.eu-central-1.amazonaws.com/goPdf/en/add-image-to-pdf.webp"),
      alt: "Add links to PDF files",
    },
    {
      title: t("Fill out PDF forms"),
      description:
        t("By clicking on a form field and starting to type, you can fill out PDF forms. In case there is no input field in the forms section, write texts using the ‘text’ tool and mark them with check-boxes or radio bullets in the Forms widget."),
      icon: (
        <TextIcon className="h-14 w-14 md:h-16 md:w-16 p-1 flex-shrink-0 bg-themecolor rounded-full flex justify-center items-center border-8 border-white" />
      ),
      img: ImgGenerator("https://gopdf.s3.eu-central-1.amazonaws.com/goPdf/en/fillout-pdf-form.webp"),
      alt: "sign pdf online",
    },
    {
      title: t("Insert and modify links in a PDF"),
      description:
        t("The edit PDF allows you to include links to web addresses or particular sections in the PDF. You can also edit all active connections that are present in the file."),
      icon: (
        <LinkIcon className="h-14 w-14 md:h-16 md:w-16 p-1 flex-shrink-0 bg-themecolor rounded-full flex justify-center items-center border-8 border-white" />
      ),
      img: ImgGenerator("https://gopdf.s3.eu-central-1.amazonaws.com/goPdf/en/insert-and-modify-links.webp"),
      alt: "sign pdf online",
    },
    {
      title: t("Conceal content in a PDF"),
      description:
        t("By using a white rectangle on a mask to cover certain parts of the PDF page, the objects remain hidden and invisible."),
      icon: (
        <TextIcon className="h-14 w-14 md:h-16 md:w-16 p-1 flex-shrink-0 bg-themecolor rounded-full flex justify-center items-center border-8 border-white" />
      ),
      img: ImgGenerator("https://gopdf.s3.eu-central-1.amazonaws.com/goPdf/en/conceal-content-in-pdf.webp"),
    },
    {
      title: t("Add Shapes"),
      description:
        t("The Shape' is a tool used in adding rectangular or elliptical shapes to the PDF page. One can also alter the fill color of borders as well as backgrounds."),
      icon: (
        <ShapesIcon className="h-14 w-14 md:h-16 md:w-16 p-1 flex-shrink-0 bg-themecolor rounded-full flex justify-center items-center border-8 border-white" />
      ),
      img: ImgGenerator("https://gopdf.s3.eu-central-1.amazonaws.com/goPdf/en/add-shapes-in-pdf.webp"),
    },
    {
      title: t("Search and substitute within PDFs"),
      description:
        t("Easy search for the instances of one word on all pages of the pdf document."),
      icon: (
        <TextIcon className="h-14 w-14 md:h-16 md:w-16 p-1 flex-shrink-0 bg-themecolor rounded-full flex justify-center items-center border-8 border-white" />
      ),
      img: ImgGenerator("https://gopdf.s3.eu-central-1.amazonaws.com/goPdf/en/add-shapes-in-pdf.webp"),
    },
    {
      title: t("Add form fields to your PDF"),
      description:
        t("In the top menu, click ‘Forms’ and select which type of information will be entered: text, multiline text, drop-down options, checkboxes, or radio buttons. Place this selected form field on your page as its name and a default value—if required."),
      icon: (
        <DocumentIcon className="h-14 w-14 md:h-16 md:w-16 p-1 flex-shrink-0 bg-themecolor rounded-full flex justify-center items-center border-8 border-white" />
      ),
      img: ImgGenerator("https://gopdf.s3.eu-central-1.amazonaws.com/goPdf/en/add-form-fields-to-pdf.webp"),
    },
    {
      title: t("Analyze PDF pages"),
      description:
        t("Simply make use of the PDF annotation for drawing lines through the highlighted portion of the PDF page. When you click on ‘Annotate’, choose a preferred style and highlight the content of the page that you want to use it on."),
      icon: (
        <TextIcon className="h-14 w-14 md:h-16 md:w-16 p-1 flex-shrink-0 bg-themecolor rounded-full flex justify-center items-center border-8 border-white" />
      ),
      img: ImgGenerator("https://gopdf.s3.eu-central-1.amazonaws.com/goPdf/en/analyze-pdf.webp"),
    },
    {
      title: t("Save Your Edits"),
      description:
        t("Once you have made the required alterations, press apply. After that, download your edited PDF document."),
      icon: (
        <TextIcon className="h-14 w-14 md:h-16 md:w-16 p-1 flex-shrink-0 bg-themecolor rounded-full flex justify-center items-center border-8 border-white" />
      ),
      img: ImgGenerator("https://gopdf.s3.eu-central-1.amazonaws.com/goPdf/en/save-edits.webp"),
    },
  ];
  const buttonTitle = t("Start Editing");
  const uploadBannerTitle = t("Free Online PDF Editor");
  const uploadBannerDescription =
    t("GoPDF free online PDF editor allows you to easily edit PDF files. Take control of your papers by editing PDFs for free today.");
  const procedureTitle = t("How to edit a PDF file");
  const procedureDescription =
    t("GoPDF provides an easy way to edit PDF files online for free from anywhere through an online PDF editor. GoPDF makes PDF editing easy for you—a solution to easy and stress-free document customization.");
  const descriptionBannerTitle =
    t("Optimize Your Online PDF Editing for Free with Our Suite");
  const descriptionBannerDescription = (
    <p className="max-w-[50rem] mt-4 text-white font-Circular-Medium">
      {t("GoPDF has a variety of PDF toolkits to assist you in dealing with your documentation. You can edit PDFs using an online PDF editor and also do ")}
      <Link
        className="text-white hover:text-white"
        target="_blank"
        to={`${process.env.REACT_APP_FRONTEND_URL}/${i18n.language}/features/convert-pdf-to-jpg`}
      >
        {t("PDF to JPG")}
      </Link>{" "}
      {t("and ")}
      <Link
        className="text-white hover:text-white"
        target="_blank"
        to={`${process.env.REACT_APP_FRONTEND_URL}/${i18n.language}/features/convert-pdf-to-word`}
      >
        {t("PDF to Word")}
      </Link>{" "}
      {t("conversions. Our AI chat helps us stand out and gives you instant assistance at every step. The best part? It's free and accessible to")}
      <Link
        className="text-white hover:text-white" target="_blank" to="/">
        {t("edit PDF")}
      </Link>{" "}
      {t("online. Wish to ")}
      <Link
        className="text-white hover:text-white"
        target="_blank"
        to={`${process.env.REACT_APP_FRONTEND_URL}/${i18n.language}/features/compress-pdf`}
      >
        {t("compress")}
      </Link>{" "}
      {t("a PDF to a smaller file size, ")}
      <Link
        className="text-white hover:text-white"
        target="_blank"
        to={`${process.env.REACT_APP_FRONTEND_URL}/${i18n.language}/features/merge-pdf`}
      >
        {t("merge PDF files")}
      </Link>
      ,{" "}
      <Link
        className="text-white hover:text-white"
        target="_blank"
        to={`${process.env.REACT_APP_FRONTEND_URL}/${i18n.language}/features/protect-pdf`}
      >
        {t("protect a PDF")}
      </Link>{" "}
      {t("document, or crop a PDF? Using our platform, you can do everything without any difficulty, with our ")}
      <Link
        className="text-white hover:text-white"
        target="_blank"
        to={`${process.env.REACT_APP_FRONTEND_URL}${i18n.language}/features/pdf-chat`}
      >
        {t("AI Chat with PDF")}
      </Link>{" "}
      {t("leading the way in your document management experience.")}
    </p>
  );
  const faqs = [
    {
      question: t("How do I sign a PDF?"),
      answer: (
        <div className="flex flex-col gap-2">
          <p>
            <b>{t("First Stage:")}</b>&nbsp;{("Select Your PDF Document Start the process")}
            {t("by pushing the ‘Upload’ button and selecting your ")}
            <Link target="_blank" to="">
              {t("PDF signature")}
            </Link>{" "}
            {t("form for signing.")}
          </p>
          <p>
            <b>{t("Next Phase:")}</b>&nbsp;{t("Create Your Signature")}
          </p>
          <p>
            {t("Tap on 'sign' and then on 'add signature' to create a new signature. You have three choices for crafting your signature:")}
          </p>
          <ul className="list-disc px-6">
            <li>
              {t("Signature Style: Write your name and choose your preferred handwriting type among the more than ten styles.")}
            </li>
            <li>
              {t("Freehand Drawing: Draw your signature directly on the screen using your mouse, trackpad, or a pointer device.")}
            </li>
            <li>
              {t("Upload a Pre-existing Image: You can make use of an already-made signature image by uploading it to your page.")}
            </li>
          </ul>
          <p>
            <b>{t("Final Step:")}</b>&nbsp;{t("Put your signature on a PDF page.")}
          </p>
          <p>
            {t("Click on “Sign” in the toolbar, select your signature, and click on any part of the resulting PDF page. Select the area on which you wish to have your signature placed. Alternatively, you can resize it by holding down any of the corner handles to make appropriate changes")}
          </p>
        </div>
      ),
    },
    {
      question: t("How Do I Edit a PDF on a Mac?"),
      answer: (
        <div>
          <h3>
            <b>{t("Browser-Based Editing:")}</b>
          </h3>
          <p>
            {t("If you're a Mac user, you can just visit the GoPDF website, use your browser, and use the GoPDF online PDF editor for all your document editing.")}
          </p>
        </div>
      ),
    },
  ];
  const jsonLd =
    { "@context": "https://schema.org", "@type": "Product", "brand": "gopdf", "name": "Online PDF Editor", "description": "Add text, shapes, images, annotations and signs to your PDF", "category": "Productivity", "aggregateRating": { "@type": "AggregateRating", "worstRating": "1", "bestRating": "5", "ratingValue": "4.4", "ratingCount": "24" } }
  return (
    <div>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta name="language" content="English" />
        <meta property="og:image" content="https://gopdf.s3.eu-central-1.amazonaws.com/Logo+go+pdf.svg" />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLd) }}
        />
        <meta httpEquiv="Cache-Control" content="max-age=2592000, must-revalidate" />
        <meta name="robots" content="index, follow"/>
      </Helmet>
      <Helmet
        link={[
          { rel: 'canonical', href: window.location.href }
        ]}
      />

      <VideoBanner
        title={uploadBannerTitle}
        description={uploadBannerDescription}
        buttonTitle={buttonTitle}
      />
      <DescriptionBanner
        title={descriptionBannerTitle}
        description={descriptionBannerDescription}
      />
      <StatsBanner />
      <ProcedureBanner
        title={procedureTitle}
        description={procedureDescription}
        steps={steps}
      />
      <FAQStatic faqs={faqs} />
    </div>
  );
}

export default EditPDF;
