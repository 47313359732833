import { useTranslation } from "react-i18next";

const Procedure = ({ title, description, steps }) => {
  const { t } = useTranslation()
  return (
    <div className="mt-20 flex flex-col gap-y-10" id="procedure">
      {steps?.map((step, i) => (
        <div key={i} className="flex-wrap sm:flex-nowrap flex sm:grid sm:grid-cols-2 justify-between">
          <div className="flex md:gap-6 gap-2">
            <div className="relative w-max flex-shrink-0">
              <div className="border-8 border-white">
                {step.icon}
              </div>
              {steps.length > 1 && i != steps.length - 1 ? (
                <div className="hidden xl:block bg-[#F3F3FF] h-full w-1 mx-auto"></div>
              ) : (
                ""
              )}
            </div>
            <div className="sm:max-w-[22.875rem] text-left">
              <h3 aria-level={3} className="text-themecolor uppercase font-Circular-Bold text-base">{t("Step")} {i < 9 ? 0 : ""}{i + 1}</h3>
              <h4 aria-level={4} className="md:text-3xl text-2xl font-Circular-Bold text-black">
                {step.title}
              </h4>
              <p className="font-Circular-Book text-lightgray pt-1">{step.description}</p>
            </div>
          </div>
          <img height={342} width={576} src={step?.img} alt="step" />
        </div>
      ))}
    </div>
  );
};
export default Procedure;
