import aws from "aws-sdk";

class AwsS3 {
  s3;
  fileUpload;
  buffer;
  fileURL;

  constructor(fileUpload = null, buffer = false, fileURL = "") {
    const config = {
      region: "eu-central-1",
      accessKeyId: "AKIARLJSTNIEV5VH7LUC",
      secretAccessKey: "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",
      signatureVersion: "v4",
    };
    this.fileUpload = fileUpload;
    this.s3 = new aws.S3(config);
    this.buffer = buffer;
    this.fileURL = fileURL;
  }

  getRandomValues = (byteArray) => {
    for (let i = 0; i < byteArray.length; i++) {
      byteArray[i] = Math.floor(Math.random() * 256);
    }
    return byteArray;
  }
  
  customRandomBytes = async (length) => {
    const byteArray = new Uint8Array(length);
    this.getRandomValues(byteArray);
    return byteArray;
  }

  getS3URL = async () => {
    try {
      //@ts-ignore
      const fileExtension = this.fileUpload?.name?.split(".")?.pop();

      const imageName = Date.now();

      const params = {
        Bucket: process.env.REACT_APP_BUCKET_NAME,
        Key: `${imageName}.${fileExtension}`,
        Expires: 60,
      };

      const uploadUrl = await this.s3.getSignedUrlPromise("putObject", params);
      let imageBuffer = null;
      if (this.buffer) {
        imageBuffer = Buffer.from(this.fileUpload, "base64");
      }

      await fetch(uploadUrl, {
        method: "PUT",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        body: this.buffer ? imageBuffer : this.fileUpload,
      });
      // console.log("uploadUrl", uploadUrl.split("?")[0]);
      return uploadUrl.split("?")[0];
    } catch (error) {
      console.log(error);
      return "";
    }
  };

  deleteFile = async () => {
    let s3Filename = this.fileURL.split("/").pop();

    await this.s3
      .deleteObject({ Bucket: process.env.REACT_APP_BUCKET_NAME, Key: s3Filename })
      .promise();
  };
}

export default AwsS3;
