import Procedure from "../procedure/Procedure";

const ProcedureBanner = ({ title, description, steps }) => {
  return (
    <section className="py-20 sm:py-28 md:py-40 container flex flex-col justify-center items-center text-center">
      <h2 aria-level={2} className="md:text-5xl text-4xl text-black1 font-Circular-Black">{title}</h2>
      <p className="mt-4 font-Circular-Medium max-w-[40rem]">{description}</p>
      <Procedure steps={steps} />
    </section>
  );
};
export default ProcedureBanner;
