import React, { useEffect, useState } from "react";
import Button from "../Button/Button";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { validateForgetpasswordForm } from "../../schemas/user.schema";
import { authService } from "../../services/auth.service";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Input from "../input/Input";
import { useTranslation } from "react-i18next";


const ForgetPasswordModal = ({ setstate1, setEmail }) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation()

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { errors, isDirty, isValid },
  } = useForm({
    defaultValues: {
      email: "",
    },

    resolver: yupResolver(validateForgetpasswordForm),
    mode: "onChange",
  });

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      // console.log(data, "data");
      data["email"] = data?.email.toLowerCase();
      setEmail(data?.email);
      const user = await authService.forgotPassword(data);
      if (user?.status === 200 || user?.status === 201) {
        toast.success("Email Sent Successfully!");
        setLoading(false);
        setstate1(6);
      }
    } catch (error) {
      console.log(error.data);
      setLoading(false);
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
        // } else if (error?.data?.message) {
        // toast.error("Email is already registered");
      } else {
        console.log(error.response);
      }
    }
  };
  useEffect(() => {
    const firstInput = document.getElementById('email');
    firstInput.focus()
    firstInput.select()
  }, [])
  return (
    <div className="sm:w-[36.688rem] xs3:w-full w-full py-10 xl:px-20 sm:px-14 px-8 m-auto rounded-lg">
      <div className="flex flex-col gap-[3.5rem]">
        <div className="flex flex-col sm:gap-16 gap-10">
          <div className="flex justify-center items-center">
            <svg
              className="w-[9.25rem] h-[2.188rem]"
              viewBox="0 0 148 35"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M32.258 34V17.026H15.974V22.546H26.278C25.91 24.846 23.564 28.894 17.538 28.894C12.064 28.894 7.234 25.076 7.234 17.716C7.234 9.988 12.57 6.63 17.492 6.63C22.782 6.63 25.496 9.988 26.324 12.886L32.396 10.724C30.786 5.71 26.048 0.695998 17.492 0.695998C8.66 0.695998 0.794 7.182 0.794 17.716C0.794 28.25 8.292 34.69 17.078 34.69C22 34.69 25.22 32.482 26.692 30.274L27.106 34H32.258ZM48.3695 29.124C45.3795 29.124 42.6195 26.916 42.6195 22.684C42.6195 18.406 45.3795 16.244 48.3695 16.244C51.3595 16.244 54.1195 18.406 54.1195 22.684C54.1195 26.962 51.3595 29.124 48.3695 29.124ZM48.3695 10.678C41.6075 10.678 36.5015 15.692 36.5015 22.684C36.5015 29.63 41.6075 34.69 48.3695 34.69C55.1315 34.69 60.2375 29.63 60.2375 22.684C60.2375 15.692 55.1315 10.678 48.3695 10.678ZM71.9208 16.244V6.906H76.9348C80.1088 6.906 82.0408 8.7 82.0408 11.598C82.0408 14.404 80.1088 16.244 76.9348 16.244H71.9208ZM77.7168 21.718C84.1568 21.718 88.4348 17.486 88.4348 11.552C88.4348 5.664 84.1568 1.386 77.7168 1.386H65.5268V34H71.8748V21.718H77.7168ZM99.6365 28.112V7.274H104.743C110.079 7.274 114.495 10.632 114.495 17.716C114.495 24.8 110.033 28.112 104.697 28.112H99.6365ZM104.927 34C114.219 34 121.073 28.02 121.073 17.716C121.073 7.412 114.265 1.386 104.973 1.386H93.2885V34H104.927ZM132.97 34V21.304H145.62V15.462H132.924V7.412H147.184V1.386H126.576V34H132.97Z"
                fill="#5E5ED2"
              />
            </svg>
          </div>
          <div className="flex flex-col gap-3 text-center">
            <h3 className="text-[1.75rem] leading-7">{t("Forgot your password?")}</h3>
            <p className="leading-none flex flex-col gap-1">
              {t("Enter your registered email to receive")}
              <span>{t("password reset instructions.")}</span>
            </p>
          </div>
        </div>
        <form className="flex flex-col gap-6 text-center" action="" onSubmit={handleSubmit(onSubmit)}>
          <div>
            <Input
              id="email"
              placeholder="Enter your email"
              className="focus:border-black1"
              name="email"
              register={register}
            />
            {errors.email && (
              <p className="at-error text-red-700 mt-1">
                {errors?.email?.message}
              </p>
            )}
          </div>
          <div className="flex flex-col gap-10">
            <Button
              type="submit"
              isLoading={loading}
              disabled={loading}
              // className={`${!(!isValid) && "disabled:opacity-70 !bg-themecolor !text-white"} py-[1.125rem] bg-themecolor rounded-[10px] mb-10`}
              className="py-[1.125rem] bg-themecolor rounded-[10px]"
            // onClick={()=>{
            //     setstate1(4)
            // }}
            >
              Submit
            </Button>

            <p className="text-black1">
              {t("Remember Password? ")}
              <span
                className="text-themecolor cursor-pointer"
                onClick={() => {
                  setstate1(1);
                }}
              >
                {t("Log in")}
              </span>{" "}
            </p>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ForgetPasswordModal;
