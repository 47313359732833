import React from "react";

export default function GoogleLoginButton({ handleGoogleLogin }) {
  return (
    <button
      onClick={() => {
        handleGoogleLogin();
      }}
      className="py-4 px-5 border border-[#D3D3D3] rounded-[14px]"
    >
      <svg
        className="w-[1.625rem] h-[1.625rem]"
        viewBox="0 0 29 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M27.5275 11.566L16.0884 11.5654C15.5833 11.5654 15.1738 11.9742 15.1738 12.4785V16.127C15.1738 16.6312 15.5833 17.04 16.0883 17.04H22.5301C21.8247 18.8677 20.5082 20.3984 18.8285 21.371L21.5752 26.1183C25.9814 23.5741 28.5864 19.11 28.5864 14.1128C28.5864 13.4012 28.5338 12.8926 28.4288 12.3198C28.3489 11.8847 27.9705 11.566 27.5275 11.566Z"
          fill="#167EE6"
        />
        <path
          d="M14.5632 22.5213C11.4107 22.5213 8.65861 20.8016 7.18052 18.2568L2.42578 20.9931C4.84544 25.1801 9.37823 27.9996 14.5632 27.9996C17.1067 27.9996 19.5068 27.3158 21.5743 26.1242V26.1177L18.8275 21.3703C17.5711 22.0979 16.1172 22.5213 14.5632 22.5213Z"
          fill="#12B347"
        />
        <path
          d="M21.5736 26.125V26.1185L18.8268 21.3711C17.5704 22.0986 16.1166 22.5221 14.5625 22.5221V28.0004C17.1061 28.0004 19.5062 27.3166 21.5736 26.125Z"
          fill="#0F993E"
        />
        <path
          d="M6.02798 14.0003C6.02798 12.4489 6.45204 10.9974 7.1806 9.74307L2.42586 7.00684C1.2258 9.06456 0.541016 11.4543 0.541016 14.0003C0.541016 16.5464 1.2258 18.9361 2.42586 20.9938L7.1806 18.2576C6.45204 17.0032 6.02798 15.5518 6.02798 14.0003Z"
          fill="#FFD500"
        />
        <path
          d="M14.5632 5.47827C16.6189 5.47827 18.5072 6.20758 19.9821 7.42071C20.346 7.71996 20.8748 7.69836 21.2081 7.36559L23.7973 4.78051C24.1755 4.40295 24.1485 3.78492 23.7446 3.43503C21.2734 1.29462 18.0582 0 14.5632 0C9.37823 0 4.84544 2.81953 2.42578 7.00651L7.18052 9.74275C8.65861 7.19797 11.4107 5.47827 14.5632 5.47827Z"
          fill="#FF4B26"
        />
        <path
          d="M19.9814 7.42071C20.3453 7.71996 20.8742 7.69836 21.2074 7.36559L23.7966 4.78051C24.1747 4.40295 24.1478 3.78492 23.7439 3.43503C21.2727 1.29456 18.0575 0 14.5625 0V5.47827C16.6182 5.47827 18.5065 6.20758 19.9814 7.42071Z"
          fill="#D93F21"
        />
      </svg>
    </button>
  );
}
