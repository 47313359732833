import React from "react";
import { ImSpinner9 } from "react-icons/im";

const Button = ({
  children,
  className,
  onClick,
  type,
  disabled,
  isLoading,
  ...rest
}) => {
  return (
    <button
      className={`inline-flex items-center justify-center px-6 sm:px-8 py-3 w-full border font-Circular-Book
       border-transparent bg-themecolor relative text-white text-base !rounded-full whitespace-nowrap leading-none 
       focus:outline-none , ${
         isLoading &&
         "relative !text-transparent hover:!text-transparent !cursor-wait !transition-none"
       }, ${className ? className : ""}`}
      type={type ? type : "button"}
      disabled={disabled ? true : false}
      onClick={onClick}
      {...rest}
    >
      {isLoading && (
        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-white">
          <ImSpinner9 className="animate-spin text-2xl" />
        </div>
      )}
      {children}
    </button>
  );
};

export default Button;
