import UploadBox from "../upload/UploadBox";

const UploadBanner = ({ title, description, file, setFile }) => {
  return (
    <section className="container flex flex-col justify-center items-center text-center py-16">
      <h1 aria-level={1} className="md:text-5xl text-4xl">{title}</h1>
      <p className="max-w-51 mt-4">{description}</p>
      <UploadBox file={file} setFile={setFile} />
    </section>
  );
};
export default UploadBanner;
