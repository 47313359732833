import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { GrFormClose } from "react-icons/gr";
import { useTranslation } from "react-i18next";

export default function Modal(props) {
  const { t } = useTranslation()

  return (
    <Transition.Root show={props.show} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 overflow-auto z-[2000]"
        onClose={() => {}}
      >
        <div className=" flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child>
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-25 0transition-opacity" />
          </Transition.Child>
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen "
            aria-hidden="true"
          >
            &#8203;
          </span>
          <div
            className={`${props.className} inline-block align-top bg-white
            rounded-3xl text-left overflow-hidden shadow-xl
            transform transition-all sm:align-middle`}
          >
            <div className="absolute top-5 right-6 flex">
              <button
                type="button"
                className="text-primary btnClose hover:text-gray-500"
                onClick={() => {
                  props.hide(false);
                }}
              >
                <span className="sr-only">{t("Close")}</span>
                <GrFormClose aria-hidden="true" />
              </button>
            </div>
            {props.children}
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
