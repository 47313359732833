import React, { useState } from "react";
import { RadioGroup } from "@headlessui/react";
import WordImg from "../../assets/editor/word.svg";
import JPEGImg from "../../assets/editor/jpeg.svg";
import Loader from "../Loader/Loader";
import JSZip from "jszip";
import { useTranslation } from "react-i18next";

const ConvertPopup = ({ setstate1, PDFUI }) => {
  const [selected, setSelected] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState(null);
  const { t } = useTranslation()


  const plans = [
    // {
    //   heading: "Office",
    //   items: [
    //     {
    //       name: "Word",
    //       image: WordImg,
    //     },
    //   ],
    // },

    {
      heading: "Image",
      items: [
        {
          name: "JPEG",
          image: JPEGImg,
        },
      ],
    },
  ];

  const handleSelect = async (value) => {
    setSelected(value.name);
    if (value.name === "JPEG") {
      var pdfdoc = await PDFUI.getCurrentPDFDoc();
      const pageCount = pdfdoc.getPageCount();
      const folderName = "pdf_images"; // set folder name
      const zipFileName = "pdf_images.zip"; // set zip file name

      // create new folder
      const folder = new JSZip();

      for (let i = 0; i < pageCount; i++) {
        var pdfpage = await pdfdoc.getPageByIndex(i);
        let lMatrix = pdfpage.getDeviceMatrix(0);
        console.log(lMatrix);
        var width = pdfpage.getWidth() * Math.abs(lMatrix.getA());
        var height = pdfpage.getHeight() * Math.abs(lMatrix.getD());
        var bitmapInfo = await pdfpage.render(
          1,
          0,
          { x: 0, y: 0, width, height },
          ["page", "annot"]
        );
        var eCanvas = document.createElement("canvas");
        eCanvas.width = bitmapInfo.width;
        eCanvas.height = bitmapInfo.height;
        var ctx = eCanvas.getContext("2d");
        const pixels = new Uint8ClampedArray(bitmapInfo.buffer);
        const imageData = new ImageData(
          pixels,
          bitmapInfo.width,
          bitmapInfo.height
        );
        ctx.putImageData(imageData, 0, 0);
        const imageBlob = await new Promise((resolve) =>
          eCanvas.toBlob(resolve, "image/jpeg", 0.9)
        );

        // create new file in the folder for each image
        const fileName = `page_${i + 1}.jpg`;
        folder.file(`${folderName}/${fileName}`, imageBlob);
      }

      // generate zip file of the folder
      folder.generateAsync({ type: "blob" }).then((content) => {
        const link = document.createElement("a");
        link.setAttribute("download", zipFileName);
        link.setAttribute("href", URL.createObjectURL(content));
        link.click();
      });
    }
  };

  return (
    <div>
      {isLoading ? (
        <div className="sm:w-[31.125rem] xs3:w-full w-[18rem] py-14 xl:px-16 sm:px-14 px-8 m-auto flex justify-center items-center rounded-lg">
          <Loader />
        </div>
      ) : (
        <div className="sm:w-[31.125rem] xs3:w-full w-[18rem] py-14 xl:px-16 sm:px-14 px-8 m-auto rounded-lg">
          <h3 className="text-center mb-3 text-xl">{t("Convert File")}</h3>

          {/* <p className="text-[#8A8A8A] text-center text-base mb-10">
            Kindly select which type of conversion you want?
          </p> */}
          <a href="" id="link"></a>
          <div className="flex justify-center">
            <RadioGroup
              value={selected}
              onChange={(e) => {
                handleSelect(e);
              }}
            >
              <RadioGroup.Label className="sr-only">
                PDF Conversions
              </RadioGroup.Label>
              <div className="flex items-center justify-center w-[20rem]">
                {plans.map((plan, index) => (
                  <div key={index} className="">
                    <h4 className="mb-7 text-center">{plan.heading}</h4>
                    <div className="flex flex-wrap gap-10">
                      {plan.items.map((item) => (
                        <RadioGroup.Option
                          key={item.name}
                          value={item}
                          className={({ active, checked }) =>
                            `${
                              active
                                ? "ring-2 ring-white ring-opacity-60 ring-offset-2 ring-offset-themecolor"
                                : ""
                            }
                      ${
                        checked
                          ? "bg-themecolor bg-opacity-75 text-white"
                          : "bg-white border border-themecolor"
                      }
                      relative  cursor-pointer rounded-lg px-5 py-4 shadow-md focus:outline-none `
                          }
                        >
                          {({ active, checked }) => (
                            <>
                              <div className="text-base">
                                <RadioGroup.Label
                                  as="div"
                                  className={`font-medium flex flex-col items-center justify-center px-5  ${
                                    checked ? "text-white" : "text-gray-900"
                                  }`}
                                >
                                  <figure className="mb-2">
                                    <img
                                      src={item.image}
                                      alt="image"
                                      width={41}
                                      height={48}
                                    />
                                  </figure>

                                  {item.name}
                                </RadioGroup.Label>
                                <RadioGroup.Description
                                  as="span"
                                  className={`inline ${
                                    checked ? "text-sky-100" : "text-gray-500"
                                  }`}
                                ></RadioGroup.Description>
                              </div>

                              {checked && (
                                <div className="shrink-0 text-white">
                                  {/* <CheckIcon className="h-6 w-6" /> */}
                                </div>
                              )}
                            </>
                          )}
                        </RadioGroup.Option>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </RadioGroup>
          </div>
        </div>
      )}
    </div>
  );
};

export default ConvertPopup;
