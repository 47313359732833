import { HttpService } from "./base.service";

class DocumentService extends HttpService {
  prefix = "adobe";

  /**
   * User
   * @paramdata
   */
  downloadPDF = (data) => this.post(`${this.prefix}/download-pdf`, data);
}

export const documentService = new DocumentService();
