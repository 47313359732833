// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html:lang(es),
html:lang(tr),
html:lang(fr) {
    header {
        nav {
            ul {
                column-gap: 1.25rem !important
            }
            ul {
                font-weight: 700 !important
            }
            @media (min-width: 1280px) {
                ul {
                    column-gap: 1rem !important
                }
            }
            @media (min-width: 1536px) {
                ul {
                    column-gap: 2rem !important
                }
            }
        }

        @media (min-width: 1024px) {
            .auth_btn {
                padding-left: 1rem !important;
                padding-right: 1rem !important
            }
        }

        @media (min-width: 1024px) {
            .auth_btn {
                font-size: 12px !important
            }
        }

        @media (min-width: 1280px) {
            .auth_btn {
                font-size: 14px !important
            }
        }

        @media (min-width: 1536px) {
            .auth_btn {
                padding-left: 1.5rem !important;
                padding-right: 1.5rem !important
            }
        }

        @media (min-width: 1536px) {
            .auth_btn {
                font-size: 1rem !important;
                line-height: 1.5rem !important
            }
        }
        .ai_btn {
            padding-top: 1rem !important;
            padding-bottom: 1rem !important
        }
        @media (min-width: 1024px) {
            .ai_btn {
                padding-top: 0.5rem !important;
                padding-bottom: 0.5rem !important
            }
        }
        @media (min-width: 1024px) {
            .ai_btn {
                padding-left: 0.75rem !important;
                padding-right: 0.75rem !important
            }
        }
        @media (min-width: 1024px) {
            .ai_btn {
                font-size: 12px !important
            }
        }
        @media (min-width: 1280px) {
            .ai_btn {
                font-size: 14px !important
            }
        }
        @media (min-width: 1536px) {
            .ai_btn {
                font-size: 1rem !important;
                line-height: 1.5rem !important
            }
        }
    }
}

header {
    nav {
        ul {
            column-gap: 0.75rem !important
        }
        @media (min-width: 1024px) {
            ul {
                column-gap: 1.5rem !important
            }
        }
        @media (min-width: 1280px) {
            ul {
                column-gap: 1.5rem !important
            }
        }
        @media (min-width: 1536px) {
            ul {
                column-gap: 2rem !important
            }
        }

    }
    .nav-item {
        font-size: 0.875rem !important;
        line-height: 1.25rem !important
    }
    .nav-item {
        font-weight: 600 !important
    }
    @media (min-width: 1280px) {
        .nav-item {
            font-size: 16px !important
        }
    }

    @media (min-width: 1280px) {
        .auth_btn {
            padding-left: 2rem !important;
            padding-right: 2rem !important
        }
    }
}

html:lang(ar) {
    #videoBanner h1,
    #videoBanner p,
    #procedure div,
    #procedure p,
    #disclosure p,
    ul {
        text-align: right !important
    }

    footer {

        span,
        ul {
            text-align: right
        }
    }
}`, "",{"version":3,"sources":["webpack://./src/lang.css"],"names":[],"mappings":"AAAA;;;IAGI;QACI;YAEQ;gBAAA;YAA0D;YAA1D;gBAAA;YAA0D;YAA1D;gBAAA;oBAAA;gBAA0D;YAAA;YAA1D;gBAAA;oBAAA;gBAA0D;YAAA;QAElE;;QAGI;YAAA;gBAAA,6BAA8E;gBAA9E;YAA8E;QAAA;;QAA9E;YAAA;gBAAA;YAA8E;QAAA;;QAA9E;YAAA;gBAAA;YAA8E;QAAA;;QAA9E;YAAA;gBAAA,+BAA8E;gBAA9E;YAA8E;QAAA;;QAA9E;YAAA;gBAAA,0BAA8E;gBAA9E;YAA8E;QAAA;QAG9E;YAAA,4BAAkF;YAAlF;QAAkF;QAAlF;YAAA;gBAAA,8BAAkF;gBAAlF;YAAkF;QAAA;QAAlF;YAAA;gBAAA,gCAAkF;gBAAlF;YAAkF;QAAA;QAAlF;YAAA;gBAAA;YAAkF;QAAA;QAAlF;YAAA;gBAAA;YAAkF;QAAA;QAAlF;YAAA;gBAAA,0BAAkF;gBAAlF;YAAkF;QAAA;IAE1F;AACJ;;AAEA;IACI;QAEQ;YAAA;QAA2D;QAA3D;YAAA;gBAAA;YAA2D;QAAA;QAA3D;YAAA;gBAAA;YAA2D;QAAA;QAA3D;YAAA;gBAAA;YAA2D;QAAA;;IAGnE;IAEI;QAAA,8BAAsD;QAAtD;IAAsD;IAAtD;QAAA;IAAsD;IAAtD;QAAA;YAAA;QAAsD;IAAA;;IAItD;QAAA;YAAA,6BAAyB;YAAzB;QAAyB;IAAA;AAEjC;;AAEA;IAOQ;;;;;;QAAA;IAA4B;;IAGhC;;QAIQ;;YAAA;QAAiB;IAEzB;AACJ","sourcesContent":["html:lang(es),\nhtml:lang(tr),\nhtml:lang(fr) {\n    header {\n        nav {\n            ul {\n                @apply 2xl:gap-x-8 xl:gap-x-4 gap-x-5 font-bold !important;\n            }\n        }\n\n        .auth_btn {\n            @apply lg:px-4 2xl:px-6 lg:text-[12px] xl:text-[14px] 2xl:text-base !important;\n        }\n        .ai_btn {\n            @apply py-4 lg:py-2 lg:px-3 lg:text-[12px] xl:text-[14px] 2xl:text-base !important;\n        }\n    }\n}\n\nheader {\n    nav {\n        ul {\n            @apply 2xl:gap-x-8 xl:gap-x-6 lg:gap-x-6 gap-x-3 !important;\n        }\n\n    }\n    .nav-item {\n        @apply xl:text-[16px] text-sm font-semibold !important;\n    }\n\n    .auth_btn {\n        @apply xl:px-8 !important;\n    }\n}\n\nhtml:lang(ar) {\n    #videoBanner h1,\n    #videoBanner p,\n    #procedure div,\n    #procedure p,\n    #disclosure p,\n    ul {\n        @apply text-right !important;\n    }\n\n    footer {\n\n        span,\n        ul {\n            @apply text-right;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
