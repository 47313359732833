/* eslint-disable react/no-unknown-property */
import React from "react";

const EditorLayoutModule = ({ heading, subHeading }) => {
  return (
    <div className="space-sm-y-3 space-y-2 p-3 p-sm-5 mb-4">
      <h3 className="font-Circular-Bold text-center text-3xl text-sm-4xl">
        {heading}
      </h3>
      <p className="text-center text-[#777777] text-xl text-sm-2xl">
        {subHeading}
      </p>
    </div>
  );
};

export default EditorLayoutModule;
