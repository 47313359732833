/* eslint-disable */
import "./App.css";
import { useEffect, useState } from "react";
import store from "./redux/store";
import { Provider } from "react-redux";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Cookies from "js-cookie";
import FoxitEditor from "./pages/foxit-editor";
import { Header } from "./components/header/header";
import Footer from "./components/footer/Footer";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EditPDF from "./pages/edit-pdf";
import FillAndSign from "./pages/fill-and-sign";
import SearchAndReplace from "./pages/search-and-replace";

function checkLang(i18n) {
  const path = window.location.pathname;
  const match = path.match(
    /\/(en-US|en|es|fr|de|it|pt|ja|ru|ko|zh-cn|zh-tw|ar|bg|ca|nl|el|hi|id|ms|pl|sv|th|tr|uk|vi)/
  );
  const lang = match ? match[1] : "en";
  if (i18n.language !== lang) {
    i18n.changeLanguage(lang);
    const html = document.getElementsByTagName("html")[0];
    html.lang = lang;
    if (lang == "ar") {
      html.dir = "rtl";
    } else {
      html.dir = "ltr";
    }
  }
}

function App({ i18n }) {
  const [token, setToken] = useState(null);
  const [file, setFile] = useState(null);
  const pages = ["/", "/edit-pdf", "/fill-and-sign", "/search-and-replace"];
  checkLang(i18n);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      const fileUrl = Cookies.get("file_url");
      const fileName = Cookies.get("file_name");
      const fileId = Cookies.get("file_Id");

      // console.log("Data of file ->", fileName, fileUrl, fileId);
      // console.log("Current domain ->", window.location.hostname);
      // console.log(
      //   "Expected public domain ->",
      //   process.env.REACT_APP_PUBLIC_DOMAIN
      // );

      // Determine if we are on the editor subdomain for dev and prod
      const isPublicDomain = window.location.hostname.endsWith(
        process.env.REACT_APP_PUBLIC_DOMAIN.replace(/"/g, "")
      );

      // console.log(`Domain matches: ${isPublicDomain}`);

      // if (fileUrl && fileName && fileId) {
      //   console.log("file_url, file_name, and file_Id cookies exist");
      // } else {
      //   console.log(
      //     "One or more of file_url, file_name, or file_Id cookies do not exist"
      //   );
      // }

      if (isPublicDomain && fileUrl && fileName && fileId) {
        const confirmationMessage =
          "Are you sure you want to leave or reload the page? Unsaved changes might be lost.";

        event.returnValue = confirmationMessage; // This will trigger the browser's confirmation dialog
        return confirmationMessage; // Some browsers require this for the dialog to show
      }
    };

    const handleUnload = () => {
      const fileUrl = Cookies.get("file_url");
      const fileName = Cookies.get("file_name");
      const fileId = Cookies.get("file_Id");

      const isPublicDomain = window.location.hostname.endsWith(
        process.env.REACT_APP_PUBLIC_DOMAIN.replace(/"/g, "")
      );

      if (isPublicDomain && fileUrl && fileName && fileId) {
        console.log("Removing File");
        Cookies.remove("file_url", {
          domain: process.env.REACT_APP_PUBLIC_DOMAIN.replace(/"/g, ""),
          path: "/",
        });
        console.log("Removed file_url");

        Cookies.remove("file_name", {
          domain: process.env.REACT_APP_PUBLIC_DOMAIN.replace(/"/g, ""),
          path: "/",
        });
        console.log("Removed file_name");

        Cookies.remove("file_Id", {
          domain: process.env.REACT_APP_PUBLIC_DOMAIN.replace(/"/g, ""),
          path: "/",
        });
        console.log("Removed file_Id");
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("unload", handleUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("unload", handleUnload);
    };
  }, []);


  return (
    <div className="App">
      <GoogleReCaptchaProvider
        reCaptchaKey={`${process.env.REACT_APP_GOOGLE_CAPTCHA_SITE_KEY}`}
        scriptProps={{
          async: false, // optional, default to false,
          defer: true, // optional, default to false
          appendTo: "body", // optional, default to "head", can be "head" or "body",
          nonce: undefined,
        }}
      >
        <GoogleOAuthProvider
          clientId={
            "612535224404-ad4snir201g44rgq8crpsiqrtheekii8.apps.googleusercontent.com"
          }
        >
          <Provider store={store}>
            <BrowserRouter>
              <Header session_id={token} />
              <Routes>
                {/* <Route path="/" element={<FoxitEditor file={file} />} />
                <Route path="/edit-pdf" element={<EditPDF />} />
                <Route path="/fill-and-sign" element={<FillAndSign file={file} setFile={setFile} />} />
                <Route path="/search-and-replace" element={<SearchAndReplace file={file} setFile={setFile} />} /> */}
                <Route path="/:lang/" element={<FoxitEditor file={file} />} />
                <Route path="/:lang/edit-pdf" element={<EditPDF />} />
                <Route
                  path="/:lang/fill-and-sign"
                  element={<FillAndSign file={file} setFile={setFile} />}
                />
                <Route
                  path="/:lang/search-and-replace"
                  element={<SearchAndReplace file={file} setFile={setFile} />}
                />
              </Routes>
              <Footer />
            </BrowserRouter>

            <ToastContainer
              position="top-right"
              autoClose={2000}
              hideProgressBar={false}
              newestOnTop={false}
              draggable={false}
              closeOnClick
              pauseOnHover
            />
          </Provider>
        </GoogleOAuthProvider>
      </GoogleReCaptchaProvider>
    </div>
  );
}

export default App;
