import React from "react";
import { Rings } from "react-loader-spinner";

const Loader = ({ width, height }) => {
  return (
    <div className="inline-block">
      <Rings
        height={height ? height : "200"}
        width={width ? width : "200"}
        color="#5E5ED2"
        ariaLabel="loading"
      />
    </div>
  );
};

export default Loader;
