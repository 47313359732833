// utils/navigation.js

import Cookies from "js-cookie";

export const navigateFoxitEditor = () => {
  const session = Cookies.get("session_id");

  let session_id = localStorage.getItem("session_id");

  if(!session_id){
    localStorage.setItem('session_id',session)
  }
   session_id = localStorage.getItem("session_id");
   console.log("session_id===>",session_id);
  
    if(session_id){
    Cookies.set("session_id", session_id, {
      domain: process.env.REACT_APP_FOXIT_PUBLIC_DOMAIN,
      path: "/",
      sameSite: "Strict",
    });
  }
};
