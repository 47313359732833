import * as React from "react";
const DownloadIcon = ({ width, height, className }) => (
    <span className={`${className}`}>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={30}
            height={30}
            viewBox="0 0 30 30"
            fill="none"
        >
            <path
                d="M20.5492 9.125C25.0492 9.5125 26.8867 11.825 26.8867 16.8875L26.8867 17.05C26.8867 22.6375 24.6492 24.875 19.0617 24.875L10.9117 24.875C5.32422 24.875 3.08672 22.6375 3.08672 17.05L3.08672 16.8875C3.08672 11.8625 4.89922 9.55 9.32422 9.1375"
                stroke="white"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15 4.1251L15 18.3501"
                stroke="white"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10.8125 15.5625L15 19.75L19.1875 15.5625"
                stroke="white"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    </span>
);
export default DownloadIcon;
