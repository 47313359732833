import React from "react";
import DescriptionBanner from "../components/features/DescriptionBanner";
import StatsBanner from "../components/features/StatsBanner";
import ProcedureBanner from "../components/features/ProcedureBanner";
import QuillSignature from "../components/icons/QuillSignature";
import UploadBanner from "../components/features/UploadBanner";
import { getPageMetadata } from "../metadata";
import { Helmet } from "react-helmet";
import UploadIcon from "../components/icons/fill-and-sign/UploadIcon";
import EditIcon from "../components/icons/fill-and-sign/EditIcon";
import SaveIcon from "../components/icons/fill-and-sign/SaveIcon";
import EyeIcon from "../components/icons/fill-and-sign/EyeIcon";
import DownloadIcon from "../components/icons/fill-and-sign/DownloadIcon";
import { useTranslation } from "react-i18next";
import { ImgGenerator } from "../frontend.services";

function FillAndSign({ file, setFile }) {
  const { t } = useTranslation()

  const { title, description } = getPageMetadata("fillAndSignPdf");
  const steps = [
    {
      title: t("Upload Your File"),
      description:
        t("Start by opening a PDF editor. Either you upload the pdf through “upload file” or simply by dropping it to the specified one."),
      icon: (
        <UploadIcon className="h-10 w-10 md:h-16 md:w-16 p-1.5 flex-shrink-0 bg-themecolor rounded-full flex justify-center items-center" />
      ),
      img: ImgGenerator("https://gopdf.s3.eu-central-1.amazonaws.com/goPdf/en/file-uploaded.webp"),
    },
    {
      title: t("Start Editing"),
      description:
        t("You can then load your PDF, after which you can use the editing tools. Learn your way around it, as well as any text or content revisions."),
      icon: (
        <EditIcon className="h-10 w-10 md:h-16 md:w-16 p-1.5 flex-shrink-0 bg-themecolor rounded-full flex justify-center items-center" />
      ),
      img: ImgGenerator("https://gopdf.s3.eu-central-1.amazonaws.com/goPdf/en/edit-pdf-text.webp"),
    },
    {
      title: t("Add Your Signature"),
      description:
        t("Click the “Sign” button to affix your signature to the document. By doing so, it will open up a signature device."),
      icon: (
        <QuillSignature className="h-10 w-10 md:h-16 md:w-16 p-1.5 flex-shrink-0 bg-themecolor rounded-full flex justify-center items-center" />
      ),
      img: ImgGenerator("https://gopdf.s3.eu-central-1.amazonaws.com/goPdf/en/add-signature.webp"),
    },
    {
      title: t("Create and Use Your Signature"),
      description:
        t("You may use the mouse or stylus for signatures in the signature tool and also create a scanned signature image. After generating or uploading your signature, press “Create and Use”"),
      icon: (
        <QuillSignature className="h-10 w-10 md:h-16 md:w-16 p-1.5 flex-shrink-0 bg-themecolor rounded-full flex justify-center items-center" />
      ),
      img: ImgGenerator("https://gopdf.s3.eu-central-1.amazonaws.com/goPdf/en/create-signature.webp"),
    },
    {
      title: t("Place your signature"),
      description:
        t("Once you have signed, come back to the main editing area. Now you will be able to drag and drop your signature wherever in the PDF file you want it. Increase or reduce the size of images; alter the angles to fit the context."),
      icon: (
        <QuillSignature className="h-10 w-10 md:h-16 md:w-16 p-1.5 flex-shrink-0 bg-themecolor rounded-full flex justify-center items-center" />
      ),
      img: ImgGenerator("https://gopdf.s3.eu-central-1.amazonaws.com/goPdf/en/place-signature.webp"),
    
    },
    {
      title: t("Save Your Edited PDF"),
      description:
        t("Remember to save your edited PDF after placing your signature and making further changes. Use “save” or “download” and save those changes you have made."),
      icon: (
        <SaveIcon className="h-10 w-10 md:h-16 md:w-16 p-1.5 flex-shrink-0 bg-themecolor rounded-full flex justify-center items-center" />
      ),
      img: ImgGenerator("https://gopdf.s3.eu-central-1.amazonaws.com/goPdf/en/save-edits.webp"),
    },
    {
      title: t("Final Review and Confirmation"),
      description:
        t("It is also important for you to ensure that your required changes are in place, signed, and appear exactly the same way you want them."),
      icon: (
        <EyeIcon className="h-10 w-10 md:h-16 md:w-16 p-1.5 flex-shrink-0 bg-themecolor rounded-full flex justify-center items-center" />
      ),
      img: ImgGenerator("https://gopdf.s3.eu-central-1.amazonaws.com/goPdf/en/final-review.webp"),

    },
    {
      title: t("Download and distribute"),
      description:
        t("Once you are done with the PDF editing, click on “download” to store the edited PDF file on your computer or other portable devices. Alternatively, you have the option of sending, printing, emailing, or faxing the modified and signed PDF as a preferred file format."),
      icon: (
        <DownloadIcon className="h-10 w-10 md:h-16 md:w-16 p-1.5 flex-shrink-0 bg-themecolor rounded-full flex justify-center items-center" />
      ),
      img: ImgGenerator("https://gopdf.s3.eu-central-1.amazonaws.com/goPdf/en/download-document.webp"),
    },
  ];
  const uploadBannerTitle = t("PDF signer: Fill and Sign online");
  const uploadBannerDescription =
    t("Using Our Free Online PDF Editor to Fill and Sign Your PDF Files and Make Them Simple For easy PDF document signing, sign up today!");
  const procedureTitle = t("How to use the Fill & Sign feature in PDF files");
  const procedureDescription =
    t("Without going through hard steps such as the movement of several mouse clicks on the computer screen, you can quickly upload your PDF files directly to the GoPDF Signer platform and conveniently incorporate your own electronic signature into the documents in question.");
  const descriptionBannerTitle =
    t("An integrated software suite for simplified PDF sign and fill management");
  const descriptionBannerDescription =
    t("Signing PDFs online for free is convenient and low-cost. It is time to say goodbye to these costs and delays that are involved in shipping the physical copies of documents. Instant signing of e-signatures on the go and at your convenience saves you time and money.");
  const jsonLd =
    { "@context": "https://schema.org", "@type": "Product", "brand": "gopdf", "name": "Online PDF Editor", "description": "Add text, shapes, images, annotations and signs to your PDF", "category": "Productivity", "aggregateRating": { "@type": "AggregateRating", "worstRating": "1", "bestRating": "5", "ratingValue": "4.4", "ratingCount": "24" } }
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta name="language" content="English" />
        <meta property="og:image" content="https://gopdf.s3.eu-central-1.amazonaws.com/Logo+go+pdf.svg" />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLd) }}
        />
        <meta httpEquiv="Cache-Control" content="max-age=2592000, must-revalidate" />
        <meta name="robots" content="index, follow"/>

      </Helmet>
      <Helmet
        link={[
          { rel: 'canonical', href: window.location.href }
        ]}
      />

      <UploadBanner
        title={uploadBannerTitle}
        description={uploadBannerDescription}
        file={file}
        setFile={setFile}
      />
      <DescriptionBanner
        title={descriptionBannerTitle}
        description={descriptionBannerDescription}
      />
      <StatsBanner />
      <ProcedureBanner
        title={procedureTitle}
        description={procedureDescription}
        steps={steps}
      />
    </>
  );
}

export default FillAndSign;
