import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { authService } from "../../services/auth.service";
import Button from "../Button/Button";
import Input from "../input/Input";
import "react-toastify/dist/ReactToastify.css";
import { yupResolver } from "@hookform/resolvers/yup";
import { validateLoginForm } from "../../schemas/user.schema";
import Cookies from "js-cookie";
import axios from "axios";
import { useGoogleLogin } from "@react-oauth/google";
import { useDispatch } from "react-redux";
import { profileUpdate } from "../../redux/slices/userSlice";
import { HttpService } from "../../services/base.service";
import GoogleLoginButton from "../googlebutton/googleLogin";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { timeZoneCityToCountry } from "../../services/countries";
import PinField from "react-pin-field";
import { useTranslation } from "react-i18next";

const LoginModal = ({ setstate1, setPopup }) => {
  const [state, setstate] = useState(true);
  const [loading, setLoading] = useState(false);
  const [verifyOTP, setVerifyOTP] = useState(false);
  const [pin, setPin] = useState("");
  const [email, setEmail] = useState("");
  const dispatch = useDispatch();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [countdown, setCountdown] = useState(60);
  const [isResending, setIsResending] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    let interval;
    if (isResending) {
      interval = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    }
    return () => {
      clearInterval(interval);
    };
  }, [isResending]);
  useEffect(() => {
    if (countdown === 0) setIsResending(false);
  }, [countdown]);
  const resendCodeHandler = async () => {
    authService
      .resendOTP({
        email: email,
      })
      .then(() => {
        toast.success(t("OTP resent to registered email"));
      })
      .catch((error) => {});
    setCountdown(60);
    setIsResending(true);
  };
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isDirty, isValid },
  } = useForm({
    defaultValues: {
      password: "",
      email: "",
    },

    resolver: yupResolver(validateLoginForm),
    mode: "onChange",
  });

  const getCountry = async () => {
    try {
      var userRegion;
      var userCity;
      var userCountry;
      var userTimeZone;

      if (Intl) {
        userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        var tzArr = userTimeZone.split("/");
        userRegion = tzArr[0];
        userCity = tzArr[tzArr.length - 1];
        //@ts-ignore
        userCountry = timeZoneCityToCountry[userCity];
      }

      return userCountry;
    } catch (error) {
      return "";
    }
  };

  const googleAuthenticationHandler = useGoogleLogin({
    onSuccess: async (response) => {
      try {
        setLoading(true);
        const country = await getCountry();

        authService
          .socialLogin({
            access_token: response.access_token,
            country: country,
            platform: "web",
            provider: "google",
            device_id: Cookies.get("device_id"),
          })
          .then((user) => {
            if (user.status === 200) {
              Cookies.set("session_id", user?.data?.data?.access_token, {
                domain: process.env.REACT_APP_PUBLIC_DOMAIN,
                path: "/",
              });
              Cookies.set("device_id", user?.data?.data?.device_id, {
                domain: process.env.REACT_APP_PUBLIC_DOMAIN,
                path: "/",
              });
              HttpService.setToken(user?.data?.data?.access_token);
              dispatch(profileUpdate(user.data));
              setLoading(false);
              setstate1(-1);
              setPopup(false);
            }
          })
          .catch((error) => {
            console.log(error);
            toast.error(error?.response?.data?.message);
          });

        // console.log(res);
      } catch (error) {
        toast.error(t("Login Failed"));
        console.log("Login Failed", error);
      }
    },
    onError: (error) => {
      toast.error(t("Login Failed"));
      console.log("Login Failed", error);
    },
  });

  const onSubmit = async (data) => {
    try {
      setLoading(true);

      if (!executeRecaptcha) {
        toast.error(t("Error occur while submitting form"));
        setLoading(false);
      }

      //@ts-ignore
      const token = await executeRecaptcha();

      if (!token) {
        toast.error(t("Error occur while submitting form"));
        setLoading(false);
      }

      const user = await authService.login({
        token: token,
        email: data.email.toLowerCase(),
        platform: "web",
        password: data.password,
        device_id: Cookies.get("device_id"),
      });
      if (user.status === 200) {
        if (user.data.data?.require2FA === true) {
          setEmail(data.email.toLowerCase());
          setLoading(false);
          setVerifyOTP(true);
          toast.warn(t("Please enter OTP code"));
        } else {
          Cookies.set("session_id", user?.data?.data?.access_token, {
            domain: process.env.REACT_APP_PUBLIC_DOMAIN,
            path: "/",
          });
          Cookies.set("device_id", user?.data?.data?.device_id, {
            domain: process.env.REACT_APP_PUBLIC_DOMAIN,
            path: "/",
          });
          HttpService.setToken(user?.data?.data?.access_token);
          // console.log(user.data);
          const obj = {
            status: user.data.status,
            success: user.data.success,
            message: user.data.message,
            data: user.data.data.profile,
          };
          dispatch(profileUpdate(obj));
          setLoading(false);
          setstate1(-1);
          setPopup(false);
        }
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      } else {
        console.log(error.response);
      }
    }
  };

  const handleVerifyOTP = async (pin) => {
    try {
      setLoading(true);

      if (!executeRecaptcha) {
        toast.error(t("Error occur while submitting form"));
        setLoading(false);
      }

      //@ts-ignore
      const token = await executeRecaptcha();

      if (!token) {
        toast.error(t("Error occur while submitting form"));
        setLoading(false);
      }

      const user = await authService.twoFALogin({
        token: token,
        email: email,
        otp: pin,
        device_id: Cookies.get("device_id"),
      });
      if (user.status === 200 || user.status === 201) {
        Cookies.set("session_id", user?.data?.data?.access_token, {
          domain: process.env.REACT_APP_PUBLIC_DOMAIN,
          path: "/",
        });
        Cookies.set("device_id", user?.data?.data?.device_id, {
          domain: process.env.REACT_APP_PUBLIC_DOMAIN,
          path: "/",
        });
        HttpService.setToken(user?.data?.data?.access_token);
        console.log(user.data);
        const obj = {
          status: user.data.status,
          success: user.data.success,
          message: user.data.message,
          data: user.data.data.profile,
        };
        dispatch(profileUpdate(obj));
        setLoading(false);
        setstate1(-1);
        setPopup(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      } else {
        console.log(error.response);
      }
    }
  };

  return (
    <div className="sm:w-[36.688rem] xs3:w-full w-full py-8 xl:px-20 sm:px-14 px-6 m-auto rounded-lg">
      {verifyOTP ? (
        <div className="flex flex-col gap-10">
          <div className="flex flex-col sm:gap-16 gap-10">
            <div className="flex justify-center items-center">
              <svg
                className="w-[9.25rem] h-[2.188rem]"
                viewBox="0 0 148 35"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M32.258 34V17.026H15.974V22.546H26.278C25.91 24.846 23.564 28.894 17.538 28.894C12.064 28.894 7.234 25.076 7.234 17.716C7.234 9.988 12.57 6.63 17.492 6.63C22.782 6.63 25.496 9.988 26.324 12.886L32.396 10.724C30.786 5.71 26.048 0.695998 17.492 0.695998C8.66 0.695998 0.794 7.182 0.794 17.716C0.794 28.25 8.292 34.69 17.078 34.69C22 34.69 25.22 32.482 26.692 30.274L27.106 34H32.258ZM48.3695 29.124C45.3795 29.124 42.6195 26.916 42.6195 22.684C42.6195 18.406 45.3795 16.244 48.3695 16.244C51.3595 16.244 54.1195 18.406 54.1195 22.684C54.1195 26.962 51.3595 29.124 48.3695 29.124ZM48.3695 10.678C41.6075 10.678 36.5015 15.692 36.5015 22.684C36.5015 29.63 41.6075 34.69 48.3695 34.69C55.1315 34.69 60.2375 29.63 60.2375 22.684C60.2375 15.692 55.1315 10.678 48.3695 10.678ZM71.9208 16.244V6.906H76.9348C80.1088 6.906 82.0408 8.7 82.0408 11.598C82.0408 14.404 80.1088 16.244 76.9348 16.244H71.9208ZM77.7168 21.718C84.1568 21.718 88.4348 17.486 88.4348 11.552C88.4348 5.664 84.1568 1.386 77.7168 1.386H65.5268V34H71.8748V21.718H77.7168ZM99.6365 28.112V7.274H104.743C110.079 7.274 114.495 10.632 114.495 17.716C114.495 24.8 110.033 28.112 104.697 28.112H99.6365ZM104.927 34C114.219 34 121.073 28.02 121.073 17.716C121.073 7.412 114.265 1.386 104.973 1.386H93.2885V34H104.927ZM132.97 34V21.304H145.62V15.462H132.924V7.412H147.184V1.386H126.576V34H132.97Z"
                  fill="#5E5ED2"
                />
              </svg>
            </div>
            <div className="flex flex-col gap-3 text-center">
              <h3 className="text-[1.75rem] leading-7">
                {t("Email Verification")}
              </h3>
              <p className="leading-none">
                {t(
                  "Please enter the 4 digit code we have sent you. Did not received the code?"
                )}{" "}
                {!isResending && (
                  <a href="#" onClick={() => resendCodeHandler()}>
                    Resend
                  </a>
                )}
                {isResending && (
                  <span>
                    {t("Resend in")} {countdown} {t("seconds")}
                  </span>
                )}
              </p>
            </div>
          </div>

          <div className="flex flex-col gap-10">
            <div className="flex w-full gap-4 max-w-xs mx-auto">
              <PinField
                type="number"
                length={4}
                validate={/^[0-9]$/}
                onChange={async (pin) => {
                  setPin(pin);
                  if (pin && pin.length === 4) {
                    handleVerifyOTP(pin);
                  }
                }}
                className="bg-transparent !w-full appearance-none overflow-y-auto border-b-2 py-3 text-center lg:text-4xl md:text-3xl sm:text-2xl text-xl font-Avenir-SemiBold  outline-none border-darkgrey"
              />
            </div>

            <Button
              isLoading={loading}
              className="py-[1.125rem] bg-themecolor rounded-[10px]"
              onClick={() => {
                handleVerifyOTP(pin);
              }}
            >
              {t("Verify")}
            </Button>
          </div>
        </div>
      ) : (
        <div className="flex flex-col gap-8">
          <div className="flex flex-col sm:gap-16 gap-8">
            <div className="flex justify-center items-center">
              <svg
                className="w-[7rem] sm:w-[9.25rem] h-[2.188rem]"
                viewBox="0 0 148 35"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M32.258 34V17.026H15.974V22.546H26.278C25.91 24.846 23.564 28.894 17.538 28.894C12.064 28.894 7.234 25.076 7.234 17.716C7.234 9.988 12.57 6.63 17.492 6.63C22.782 6.63 25.496 9.988 26.324 12.886L32.396 10.724C30.786 5.71 26.048 0.695998 17.492 0.695998C8.66 0.695998 0.794 7.182 0.794 17.716C0.794 28.25 8.292 34.69 17.078 34.69C22 34.69 25.22 32.482 26.692 30.274L27.106 34H32.258ZM48.3695 29.124C45.3795 29.124 42.6195 26.916 42.6195 22.684C42.6195 18.406 45.3795 16.244 48.3695 16.244C51.3595 16.244 54.1195 18.406 54.1195 22.684C54.1195 26.962 51.3595 29.124 48.3695 29.124ZM48.3695 10.678C41.6075 10.678 36.5015 15.692 36.5015 22.684C36.5015 29.63 41.6075 34.69 48.3695 34.69C55.1315 34.69 60.2375 29.63 60.2375 22.684C60.2375 15.692 55.1315 10.678 48.3695 10.678ZM71.9208 16.244V6.906H76.9348C80.1088 6.906 82.0408 8.7 82.0408 11.598C82.0408 14.404 80.1088 16.244 76.9348 16.244H71.9208ZM77.7168 21.718C84.1568 21.718 88.4348 17.486 88.4348 11.552C88.4348 5.664 84.1568 1.386 77.7168 1.386H65.5268V34H71.8748V21.718H77.7168ZM99.6365 28.112V7.274H104.743C110.079 7.274 114.495 10.632 114.495 17.716C114.495 24.8 110.033 28.112 104.697 28.112H99.6365ZM104.927 34C114.219 34 121.073 28.02 121.073 17.716C121.073 7.412 114.265 1.386 104.973 1.386H93.2885V34H104.927ZM132.97 34V21.304H145.62V15.462H132.924V7.412H147.184V1.386H126.576V34H132.97Z"
                  fill="#5E5ED2"
                />
              </svg>
            </div>
            <div className="flex flex-col gap-3 text-center">
              <h3 className="text-[2rem] text-center leading-8">
                {t("Welcome!")}
              </h3>
            </div>
          </div>
          <form action="" onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-3">
              <Input
                placeholder={t("Enter your email")}
                className="focus:border-black1"
                name="email"
                register={register}
              />
              {errors.email && (
                <p className="at-error text-red-700 mt-1">
                  {errors?.email?.message}
                </p>
              )}
            </div>
            <div className="mb-3">
              <div className=" relative">
                <Input
                  placeholder={t("Enter your password")}
                  type={state ? "password" : "text"}
                  className="pr-12 focus:border-black1"
                  name="password"
                  register={register}
                />
              </div>
              {errors.password && (
                <ul className="at-error list-disc list-inside text-red-700 mt-1">
                  <li>{t("Have at least 1 uppercase")}</li>
                  <li>{t("Have at least 1 special character")}</li>
                  <li>{t("Have at least 1 number")}</li>
                  <li>{t("Have at least 8 characters")}</li>
                </ul>
              )}
            </div>
            <p
              className="text-right text-black1 mb-12 cursor-pointer"
              onClick={() => {
                setstate1(5);
              }}
            >
              {t("Forgot your password?")}
            </p>
            <Button
              type="submit"
              isLoading={loading}
              disabled={isValid == false ? true : loading}
              className={`${
                !!isValid && "disabled:opacity-70 !bg-themecolor !text-white"
              } py-[1.125rem] bg-[#CCCCCC] text-[#777777] rounded-[10px] mb-5`}
            >
              {t("Login")}
            </Button>
            <div className="flex items-center gap-8 justify-center mb-5">
              <div className="w-[8.188rem] h-[1px] bg-black1"></div>
              <p className="text-black1 text-base  text-center min-w-[130px]">
                {t("Or, Login with...")}
              </p>
              <div className="w-[8.188rem] h-[1px] bg-black1"></div>
            </div>
            <div className="flex gap-5 justify-center mb-12">
              {/* <FacebookLogin
              appId="829800948090263"
              fields="name,email,picture"
              onClick={componentClicked}
              callback={responseFacebook}
              cssClass="customFacebookButton"
              icon="fa-facebook"
              textButton=""
            /> */}
              <GoogleLoginButton
                handleGoogleLogin={googleAuthenticationHandler}
              />
            </div>
            <p className="text-center text-black1">
              {t("Don't have an account? ")}
              <span
                className="text-themecolor cursor-pointer"
                onClick={() => {
                  setstate1(2);
                }}
              >
                {t("Sign up")}
              </span>{" "}
            </p>
          </form>
        </div>
      )}
    </div>
  );
};

export default LoginModal;
