import * as React from "react";
const LinkIcon = ({...props}) => (
    <span className={props.className}>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={30}
            height={30}
            viewBox="0 0 31 30"
            fill="none"
        >
            <path
                d="M16.4118 13.6741C19.2369 16.4871 19.2369 21.0379 16.4118 23.8384C13.5867 26.6389 9.01643 26.6514 6.20393 23.8384C3.39143 21.0254 3.37887 16.4746 6.20393 13.6741"
                stroke="white"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M13.3071 16.7644C10.369 13.8389 10.369 9.08804 13.3071 6.15001C16.2451 3.21198 21.0163 3.22448 23.9669 6.15001C26.9176 9.07553 26.905 13.8264 23.9669 16.7644"
                stroke="white"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    </span>
);
export default LinkIcon;
