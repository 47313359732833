const QuillSignature = ({ ...props }) => (
  <span className={props.className}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={30}
      height={30}
      viewBox="0 0 30 30"
      fill="none"
      // {...props}
    >
      <path
        d="M2.8125 25.3125C5.625 18.1247 12.6562 4.6875 17.3438 4.6875C21.5625 4.6875 9.375 24.375 14.0625 24.375C17.3438 24.375 19.6875 14.5312 22.0312 14.5312C24.375 14.5312 21.0938 22.9688 22.9688 22.9688C25.3125 22.9688 26.7188 19.2188 26.7188 19.2188"
        stroke="#F6F6FF"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </span>
);
export default QuillSignature;
