import React from "react";

import ProgressBar from "@ramonak/react-progress-bar";

const ProgressBarLine = ({ percent }) => {
  return (
    <ProgressBar
      className=""
      completed={percent}
      animateOnRender={true}
      bgColor="#2E2E2E"
      width="90%"
      height="10px"
      customLabelStyles={{
        position: "absolute",
        display: "none",
        right: "0px",
        color: "black",
      }}
    />
  );
};
export default ProgressBarLine;
