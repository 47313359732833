import { useState } from "react";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import Button from "../Button/Button";
import { RiErrorWarningFill } from "react-icons/ri";
import { GrFormClose } from "react-icons/gr";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { PDFDocument } from 'pdf-lib';

const UploadBox = ({ file, setFile }) => {
  const { t, i18n } = useTranslation()

  const [fileLength, setFileLength] = useState(0);

  const isPDFValid = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = async (event) => {
        try {
          const arrayBuffer = event.target.result;
          await PDFDocument.load(arrayBuffer);
          resolve(true);
        } catch (error) {
          console.error('Error validating file:', error);
          resolve(false);
        }
      };
      reader.onerror = () => {
        console.error('Error reading file');
        resolve(false);
      };
      reader.readAsArrayBuffer(file);
    });
  };


  const formatBytes = (bytes, decimals = 2) => {
    if (!+bytes) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  };

  const maxFileLength = 500; // for free plan use user-defined value acc to subscription plan
  const maxTitleLength = 35; // title length text management of frontend

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    multiple: false,
    accept: {
      "image/pdf": [".pdf"],
    },
    onDrop: async (acceptedFiles) => {
      const file = acceptedFiles[0];

      const isValidPDF = await isPDFValid(file);

      if (!isValidPDF) {
        toast.error(t("The selected PDF file is corrupted. Please select a valid PDF file."));
        return;
      }

      var reader = new FileReader();
      // @ts-ignore
      reader.readAsBinaryString(acceptedFiles[0]);
      reader.onloadend = function () {
        // @ts-ignore
        var count = reader.result?.match(/\/Type[\s]*\/Page[^s]/g).length;
        setFileLength(count);
      };

      if (acceptedFiles[0]["size"] > 100000000) {
        toast.error("File size cannot be more then 100 MB");
      } else {
        Cookies.remove("file_Id", {
          domain: process.env.REACT_APP_PUBLIC_DOMAIN,
          path: "/",
        });

        Cookies.remove("file_url", {
          domain: process.env.REACT_APP_PUBLIC_DOMAIN,
          path: "/",
        });

        Cookies.set("file_name", acceptedFiles[0]?.name, {
          domain: process.env.NEXT_PUBLIC_DOMAIN,
          path: "/",
        });

        setFile(acceptedFiles[0]);
      }
    },
  });

  return (
    <div className="w-full">
      {!file && (
        <div
          {...getRootProps({ className: "dropzone" })}
          className="flex flex-wrap w-full mb-6 justify-center items-center md:p-6 rounded-3xl mt-16"
        >
          <div className="cursor-pointer flex justify-center items-center  border border-grey-700 border-dashed p-6 rounded-3xl w-full md:max-w-[63.25rem] h-[23.25rem]">
            <input
              id="pdfFiles"
              accept=".pdf"
              type="file"
              className="w-full absolute"
              value={[]}
              {...getInputProps()}
            />
            <div className="flex flex-col justify-center items-center w-full">
              <i className="icon-uplaodfile h-[4.375rem] w-[4.375rem] mb-4 rounded-full bg-black flex justify-center items-center text-[2.375rem] text-white shrink-0"></i>
              <p className="text-black text-base">
                {" "}
                <span className="border-b border-black">
                  {t("Click to upload PDF files")}
                </span>{" "}
                {t("or drag and drop")}
              </p>
              <p className="text-black text-base">
                {" "}
                <span className="border-b border-black">
                  {t("File should be less than 100 MB")}
                </span>{" "}
                {t("and allowed page size is 500")}
              </p>
            </div>
          </div>
        </div>
      )}

      {file && (
        <div className="flex flex-wrap w-full mb-6 justify-center items-center">
          <div className="cursor-pointer flex justify-center items-center relative border border-[#818181] border-dashed p-6 rounded-3xl mt-16 w-full md:max-w-[63.25rem] h-[23.25rem]">
            <GrFormClose
              onClick={() => {
                setFile(null);
              }}
              className="absolute top-3 right-3 text-3xl text-[#2E2E2E] cursor-pointer"
            />

            <div className="flex flex-col gap-4 px-4 mb-2 w-full">
              <i className="py-2 px-2 rounded-[10px] icon-pdffile text-black1 text-[100px] shrink-0"></i>
              <div className="w-full">
                <p className="text-black1 text-base sm:text-xl">
                  {
                    //@ts-ignore
                    file.name.length > maxTitleLength
                      ? //@ts-ignore
                      file.name.substring(0, maxTitleLength) + "..."
                      : // @ts-ignore
                      file?.name
                  }
                </p>
                <p className="text-black1 text-xl">
                  {
                    //@ts-ignore
                    formatBytes(file.size)
                  }
                </p>
              </div>
            </div>
          </div>
        </div>
      )}

      {fileLength <= maxFileLength ? (
        <div>
          {file && (
            <div className="flex justify-center items-center xs:flex-col gap-4">
              <Link to={`/${i18n.language}`}>
                <Button className="py-4 px-[5.875rem] w-[fit-content] rounded-[10px]">
                  {t("Start Editing")}
                </Button>
              </Link>
            </div>
          )}
        </div>
      ) : (
        <div className="flex items-center justify-center gap-2 w-full">
          <RiErrorWarningFill className="h-max w-10" color="#B91C1C" />
          <p className="text-red-700">
            {t("Max number of pages in current plan is 500 pages.")}
          </p>
        </div>
      )}
    </div>
  );
};
export default UploadBox;
