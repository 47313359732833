import React from "react";
import Button from "../Button/Button";
import { useTranslation } from "react-i18next";


const EmailSentModal = ({ setstate1 }) => {
  const { t } = useTranslation()

  return (
    <div className="sm:w-[36.688rem] xs3:w-full w-full py-10 xl:px-20 sm:px-14 px-8 m-auto rounded-lg">
      <div className="flex flex-col gap-[3.5rem]">
        <div className="flex flex-col sm:gap-16 gap-10">
            <div className="flex justify-center items-center">
              <svg
                className="w-[9.25rem] h-[2.188rem]"
                viewBox="0 0 148 35"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M32.258 34V17.026H15.974V22.546H26.278C25.91 24.846 23.564 28.894 17.538 28.894C12.064 28.894 7.234 25.076 7.234 17.716C7.234 9.988 12.57 6.63 17.492 6.63C22.782 6.63 25.496 9.988 26.324 12.886L32.396 10.724C30.786 5.71 26.048 0.695998 17.492 0.695998C8.66 0.695998 0.794 7.182 0.794 17.716C0.794 28.25 8.292 34.69 17.078 34.69C22 34.69 25.22 32.482 26.692 30.274L27.106 34H32.258ZM48.3695 29.124C45.3795 29.124 42.6195 26.916 42.6195 22.684C42.6195 18.406 45.3795 16.244 48.3695 16.244C51.3595 16.244 54.1195 18.406 54.1195 22.684C54.1195 26.962 51.3595 29.124 48.3695 29.124ZM48.3695 10.678C41.6075 10.678 36.5015 15.692 36.5015 22.684C36.5015 29.63 41.6075 34.69 48.3695 34.69C55.1315 34.69 60.2375 29.63 60.2375 22.684C60.2375 15.692 55.1315 10.678 48.3695 10.678ZM71.9208 16.244V6.906H76.9348C80.1088 6.906 82.0408 8.7 82.0408 11.598C82.0408 14.404 80.1088 16.244 76.9348 16.244H71.9208ZM77.7168 21.718C84.1568 21.718 88.4348 17.486 88.4348 11.552C88.4348 5.664 84.1568 1.386 77.7168 1.386H65.5268V34H71.8748V21.718H77.7168ZM99.6365 28.112V7.274H104.743C110.079 7.274 114.495 10.632 114.495 17.716C114.495 24.8 110.033 28.112 104.697 28.112H99.6365ZM104.927 34C114.219 34 121.073 28.02 121.073 17.716C121.073 7.412 114.265 1.386 104.973 1.386H93.2885V34H104.927ZM132.97 34V21.304H145.62V15.462H132.924V7.412H147.184V1.386H126.576V34H132.97Z"
                  fill="#5E5ED2"
                />
              </svg>
            </div>
            <div className="flex flex-col gap-3 text-center">
              <h3 className="text-[1.75rem] leading-7">{t("Email has been sent!")}</h3>
              <p className="leading-none">
                {t("Check your inbox for 4 digit OTP we have sent you.")}
              </p>
            </div>
        </div>
        <div className="flex flex-col gap-10">
          <div className="flex justify-center items-center">
            <svg
              width="181"
              height="167"
              viewBox="0 0 181 167"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g opacity="0.06">
                <ellipse
                  cx="25.8048"
                  cy="156.473"
                  rx="10.63"
                  ry="10.5273"
                  fill="#0A4F40"
                />
                <ellipse
                  cx="6.31498"
                  cy="137.874"
                  rx="5.31498"
                  ry="5.26363"
                  fill="#0A4F40"
                />
                <ellipse
                  cx="131.394"
                  cy="61.7278"
                  rx="49.6065"
                  ry="49.1272"
                  fill="#0A4F40"
                />
              </g>
              <g filter="url(#filter0_d_416_187)">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M18.7197 65.9406C18.7197 61.9038 20.4236 58.0545 23.4119 55.3403L71.9463 11.2583C75.8314 7.72967 81.7805 7.7951 85.587 11.4083L131.897 55.3672C134.745 58.071 136.358 61.8259 136.358 65.7532V128.529C136.358 134.46 131.549 139.269 125.618 139.269H29.4597C23.5282 139.269 18.7197 134.46 18.7197 128.529V65.9406Z"
                  fill="#F5F5F5"
                />
              </g>
              <mask
                id="mask0_416_187"
                style={{ maskType: "alpha" }}
                maskUnits="userSpaceOnUse"
                x="18"
                y="8"
                width="119"
                height="132"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M18.7197 65.9403C18.7197 61.9034 20.4236 58.0541 23.4119 55.34L71.9463 11.2579C75.8314 7.72927 81.7805 7.79471 85.5869 11.408L131.897 55.3668C134.745 58.0706 136.358 61.8255 136.358 65.7528V128.528C136.358 134.46 131.549 139.268 125.618 139.268H29.4597C23.5282 139.268 18.7197 134.46 18.7197 128.528V65.9403Z"
                  fill="white"
                />
              </mask>
              <g mask="url(#mask0_416_187)">
                <g filter="url(#filter1_d_416_187)">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M18.7197 59.6025L73.3797 98.6093C75.8681 100.385 79.2095 100.385 81.6979 98.6093L136.358 59.6025V132.109C136.358 136.063 133.152 139.269 129.198 139.269H25.8797C21.9254 139.269 18.7197 136.063 18.7197 132.109V59.6025Z"
                    fill="white"
                  />
                </g>
                <g filter="url(#filter2_d_416_187)">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M73.3797 100.262C75.8681 98.4859 79.2095 98.4859 81.698 100.262L136.358 139.268H18.7197L73.3797 100.262Z"
                    fill="white"
                  />
                </g>
              </g>
              <ellipse
                cx="63.696"
                cy="56.1738"
                rx="4.32493"
                ry="4.28315"
                fill="#B3B3B3"
              />
              <ellipse
                cx="93.9714"
                cy="56.1738"
                rx="4.32493"
                ry="4.28315"
                fill="#B3B3B3"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M66.29 70.1056C66.29 70.8993 66.8132 71.5946 67.5843 71.7829C69.7806 72.3193 74.4405 73.3076 78.9624 73.3076C83.4629 73.3076 87.9635 72.3287 90.0973 71.7906C90.86 71.5982 91.3747 70.9073 91.3747 70.1207C91.3747 68.9264 90.2177 68.0523 89.0538 68.3202C86.6884 68.8646 82.8197 69.5845 78.8992 69.5845C74.9488 69.5845 70.9983 68.8536 68.5989 68.3077C67.4389 68.0438 66.29 68.9159 66.29 70.1056Z"
                fill="#B3B3B3"
              />
              <defs>
                <filter
                  id="filter0_d_416_187"
                  x="0.81974"
                  y="0.0623035"
                  width="153.439"
                  height="166.414"
                  filterUnits="userSpaceOnUse"
                  colorInterpolationFilters="sRGB"
                >
                  <feFlood floodOpacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dy="9.30799" />
                  <feGaussianBlur stdDeviation="8.94999" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.226735 0 0 0 0 0.437054 0 0 0 0 0.687819 0 0 0 0.0769928 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_416_187"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_416_187"
                    result="shape"
                  />
                </filter>
                <filter
                  id="filter1_d_416_187"
                  x="6.54774"
                  y="45.2825"
                  width="141.983"
                  height="104.011"
                  filterUnits="userSpaceOnUse"
                  colorInterpolationFilters="sRGB"
                >
                  <feFlood floodOpacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dy="-2.148" />
                  <feGaussianBlur stdDeviation="6.086" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.108708 0 0 0 0 0.237823 0 0 0 0 0.391768 0 0 0 0.0690104 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_416_187"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_416_187"
                    result="shape"
                  />
                </filter>
                <filter
                  id="filter2_d_416_187"
                  x="13.7077"
                  y="91.7697"
                  width="127.663"
                  height="50.3629"
                  filterUnits="userSpaceOnUse"
                  colorInterpolationFilters="sRGB"
                >
                  <feFlood floodOpacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dy="-2.148" />
                  <feGaussianBlur stdDeviation="2.506" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.252325 0 0 0 0 0.423517 0 0 0 0 0.627631 0 0 0 0.02607 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_416_187"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_416_187"
                    result="shape"
                  />
                </filter>
              </defs>
            </svg>
          </div>
          <Button
            className="py-[1.125rem] rounded-[10px]"
            onClick={() => {
              setstate1(10);
            }}
          >
            Continue
          </Button>
          </div>
        </div>
    </div>
  );
};

export default EmailSentModal;
